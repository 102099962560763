import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import UnitService from '../../services/UnitService';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ROLE_MAP } from '../../locales/constants';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { People } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Iconify from '../../components/iconify';
import UserService from '../../services/UserService';
import Divider from "@mui/material/Divider";
import PullToRefresh from 'react-simple-pull-to-refresh';
import useResponsive from '../../hooks/useResponsive';

export default function Units() {
  const [units, setUnits] = useState([]);
  const [managers, setManagers] = useState([]);

  const fetchUnits = async () => {
    try {
      const unitList = await UnitService.getUnits();
      const managerList = await UserService.getManagers();

      if (!unitList.isAxiosError) {
        setUnits(unitList);
      }

      if (!managerList.isAxiosError) {
        setManagers(managerList);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const deleteUnit = async (id) => {
    try {
      await UnitService.deleteOrganization(id);
      fetchUnits();
    } catch(error) {
      console.log(error);
    }
  }   

  useEffect(() => { 
    fetchUnits();
  }, []);

  const columns = [
    { 
      field: 'name', 
      headerName: 'Birim Adı',
      width: 170, 
      sortable: false, 
      headerAlign: 'center',
      align: 'center' 
    },
    { 
      field: 'manager', 
      headerName: 'Yönetici',
      width: 170, 
      sortable: false, 
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        const { row } = params;

        if (!row.manager) {
          return '';
        }

        const { manager } = row;

        return manager.name + ' ' + manager.surname;
      }
    },
    {
      field: "options",
      headerName: "",
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      width: 185, 
      renderCell: (params) => {
        return <CustomizedMenus row={params.row} managers={managers} fetchUnits={fetchUnits} deleteUnit={deleteUnit}></CustomizedMenus>;
      }
    }
  ];

  const handleRefresh = async () =>  {
    await fetchUnits();
  }

  const lgUp = useResponsive('up', 'lg');

  return (
    <PullToRefresh onRefresh={handleRefresh} isPullable={!lgUp}>
      <Container component="main" maxWidth="lg" sx={{backgroundColor: "#F9FAFB"}}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <ApartmentIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Birimler
        </Typography>
        <DataGrid
          sx={{ minHeight: '400px', mt: 3, mb: 3, maxWidth: { lg: '70%', md: '70%', sm: '80%', xs: '90%' }}}
          rows={units}
          columns={columns}
          hideFooter={true}
          disableColumnSelector={true}
          disableColumnFilter={true}
          disableColumnMenu={true}
          disableDensitySelector={true}
          isRowSelectable={false}
        />
        <CustomizedCrateDialogs fetchUnits={fetchUnits}></CustomizedCrateDialogs>
        </Box>
      </Container>
    </PullToRefresh>
  );
}

function BootstrapDialogTitle(props) {
  const { children, onClose, padding, ...other } = props;

  return (
    <DialogTitle 
      sx={{ m: 0, p: padding }} 
      {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(),
  },
}));

const userColumns = [
  { 
    field: 'name', 
    headerName: 'Ad',
    width: 165, 
    sortable: false, 
    headerAlign: 'center',
    align: 'center' 
  },
  { 
    field: 'surname', 
    headerName: 'Soyad', 
    width: 165, 
    sortable: false, 
    headerAlign: 'center',
    align: 'center' 
  },
  {
      field: 'userName',
      headerName: 'Kullanıcı Adı',
      sortable: false,
      width: 130,
      headerAlign: 'center',
      align: 'center'
  },
  {
      field: 'organization',
      headerName: 'Birim',
      sortable: false,
      width: 160,
      valueGetter: (params) =>
      `${params.row.organization.name || ''}`,
      headerAlign: 'center',
      align: 'center'
  },
  {
    field: 'role',
    headerName: 'Rol',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
    `${ROLE_MAP[params.row.role.name] || ''}`,
    headerAlign: 'center',
     align: 'center'
  }
];


export function CustomizedDialogs({ id }) {
  const [open, setOpen] = React.useState(false);
  const [users, setUsers] = React.useState(false);

  const fetchUsers = async () => {
    const userList = await UnitService.getUnitUsers(id);
    if (!userList.isAxiosError) {
      setUsers(userList);
    }
  }

  useEffect(() => {
    if (open) {
      fetchUsers();
    }
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <MenuItem onClick={handleClickOpen} disableRipple>
        <Iconify icon={'eva:list-outline'} sx={{ mr: 2 }} />
        Kullanıcı Listesi
      </MenuItem>
      <BootstrapDialog
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} padding={3}>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Container component="main" maxWidth="md">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 5,
                marginBottom: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <People />
              </Avatar>
              <Typography component="h1" variant="h5">
                Kullanıcılar
              </Typography>
              <DataGrid
                sx={{ minHeight: '400px', mt: 3, width: '100%' }}
                rows={users}
                columns={userColumns}
                hideFooter={true}
                disableColumnFilter={true}
                disableColumnMenu={true}
              />
            </Box>
          </Container>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export function CustomizedEditDialogs({ unit, managers, fetchUnits }) {
  const [open, setOpen] = React.useState(false);
  const [formError, setFormError] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFormError(false);
    setOpen(false);
  };

  const updateUnit = async (event) => {
    setFormError(false);
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const body = {
      name: data.get('name'),
      managerId: data.get('managerId')
    }

    try {
      await UnitService.updateOrganization(unit.id, body);
      setOpen(false);
      fetchUnits();
    } catch (error) {
      setFormError(true)
    }
  };

  return (
    <div>
      <MenuItem onClick={handleClickOpen} disableRipple>
        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
        Güncelle
      </MenuItem>
      <BootstrapDialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} padding={3}>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Container component="main" maxWidth="sm">
            <CssBaseline />

            
            <Box
              sx={{
                marginTop: 5,
                marginBottom: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <ApartmentIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Birim Güncelle
              </Typography>
              <Box component="form" onSubmit={updateUnit} sx={{ mt: 3, width: '100%', pl: 2, pr: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="name"
                      label="Birim Adı"
                      name="name"
                      autoComplete="name"
                      defaultValue={unit.name}
                      inputProps={{ maxLength: 255 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="managerId"
                      name="managerId"
                      select
                      label="Yönetici"
                      required
                      fullWidth
                      defaultValue={unit.managerId}
                    >
                      {managers.map(manager => <MenuItem value={manager.id}>{manager.name + ' ' + manager.surname}</MenuItem>)}
                    </TextField>
                  </Grid>
                </Grid>
                { formError ? <Grid item xs={12} sx={{ mt: 3 }}>
                  <Alert severity="error">Kayıt başarısız, lütfen daha sonra tekrar deneyin</Alert>
                </Grid> : <></>}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, minHeight:'46px !important'}}
                >
                  Güncelle
                </Button>
              </Box>
            </Box>
          </Container>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export function CustomizedCrateDialogs({ fetchUnits }) {
  const [open, setOpen] = React.useState(false);
  const [formError, setFormError] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setFormError(false)
    setOpen(false);
  };

  const createUnit = async (event) => {
    setFormError(false);
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const body = {
      name: data.get('name'),
    }

    try {
      await UnitService.createOrganization(body);
      setOpen(false);
      fetchUnits();
    } catch (error) {
      setFormError(true)
    }
  };

  return (
    <div>
      <Button color='primary' variant="contained" onClick={handleClickOpen}>
        <AddToPhotosIcon></AddToPhotosIcon> <span style={{ paddingLeft: '10px' }}>Birim Ekle</span>
      </Button>
      <BootstrapDialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} padding={3}>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Container component="main" maxWidth="sm">
            <Box
              sx={{
                marginTop: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <ApartmentIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Birim Ekle
            </Typography>
            <Box component="form" onSubmit={createUnit} sx={{ mt: 3, width: '100%', pl: 2, pr: 2 }}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="name"
                      label="Birim Adı"
                      name="name"
                      autoComplete="name"
                    />
                  </Grid>
                  { formError ? <Grid item xs={12} sx={{ mt: 3 }}>
                    <Alert severity="error">Kayıt başarısız, lütfen daha sonra tekrar deneyin</Alert>
                  </Grid> : <></>}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, minHeight:'46px !important'}}
                  >
                    Kaydet
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Container>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

function ConfirmationDialogRaw(props) {
  const { id, handleOk } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    handleCancel();
    handleOk(id);
  };

  return (
    <div>
      <MenuItem sx={{ color: 'error.main' }}  onClick={handleOpen} disableRipple>
        <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
        Sil
      </MenuItem>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={open}
      >
        <DialogTitle>Onay</DialogTitle>
        <DialogContent dividers>
          <Typography>Birim silinecektir. Onaylıyor musunuz?</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='error' autoFocus onClick={handleCancel}>
            İptal
          </Button>
          <Button variant='outlined' color='primary' onClick={handleConfirm}>Onayla</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export function CustomizedMenus({ row, managers, fetchUnits, deleteUnit }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <CustomizedDialogs id={row.id}></CustomizedDialogs>
        <CustomizedEditDialogs unit={row} managers={managers} fetchUnits={fetchUnits}></CustomizedEditDialogs>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <ConfirmationDialogRaw id={row.id} handleOk={deleteUnit}></ConfirmationDialogRaw>
      </StyledMenu>
    </div>
  );
}