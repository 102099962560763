import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { InputAdornment, Container, Button,  Box, Grid, TextField, IconButton } from '@mui/material';
// hooks
// components
import Logo from '../../components/logo';
import Iconify from '../../components/iconify';
// sections
import UserService from '../../services/UserService';
import { UserContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import CircularProgress  from '@mui/material/CircularProgress';
import Cookie from '../../utils/cookie';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PlaceIcon from '@mui/icons-material/Place';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ListItemButton from '@mui/material/ListItemButton';
import Slide from '@mui/material/Slide';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Capacitor } from '@capacitor/core';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const navigate = useNavigate();

  const { setUser } = useContext(UserContext);

  const [formError, setFormError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const [open, setOpen] = useState(false);
  const [isStageOne, setIsStageOne] = useState(true);
  const [stageOneSelected, setStageOneSelected] = useState('');

  const [isStageTwo, setIsStageTwo] = useState(false);
  const [organization, setOrganization] = useState('');

  const handleStageOne = (selected) => {
    setStageOneSelected(selected);
    setIsStageOne((prev) => !prev);
  };

  const handleStageTwo = () => {
    setIsStageTwo((prev) => !prev);
  };

  const goStageOne = () => {
    setIsStageOne(true);
    setIsStageTwo(false);
    setStageOneSelected('');
  }

  const getStage = () => {
    if (isStageOne) {
      return 0;
    }
    return 1;
  }

  const handleStageTwoSelected = (selected) => {
    Cookie.setName('organization', selected);
    setOrganization(selected);
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const verifyToken = async () => {
      const accessToken = await Cookie.getCookie('access_token');
      if (accessToken) {
        navigate('/dashboard', { replace: true });
      } else {
        setPageLoading(false);
      }
    }

    const verifyMobileOrganization = async () => {
      if (Capacitor.isNativePlatform()) {
        const organization = await Cookie.getName('organization');
        if (!organization || organization === '') {
          handleClickOpen();
        } else {
          setOrganization(organization);
        }
      }
    }
    
    verifyToken();
    verifyMobileOrganization();
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const data = new FormData(event.currentTarget);

    const body = {
      userName: data.get('userName'),
      password: data.get('password'),
    };

    try {
      const response = await UserService.login(body);
      setUser(response);
      setLoading(false);

      navigate('/dashboard', { replace: true });
    } catch (e) {
      console.log(e);
      setLoading(false);
      setFormError(true);
    }
  };

  const getStageTwoList = () => {
    if (stageOneSelected === 'cankiri') {
      return (
        <List dense={false}>
          <ListItemButton onClick={() => handleStageTwoSelected('cioistakip')}>
            <ListItemIcon>
              <CorporateFareIcon />
            </ListItemIcon>
            <ListItemText
              primary="Özel İdare"
            />
          </ListItemButton>
        </List>
      );
    } else if (stageOneSelected === 'erzincan') {
      return (
        <List dense={false}>
          <ListItemButton onClick={() => handleStageTwoSelected('evistakip')}>
            <ListItemIcon>
              <CorporateFareIcon />
            </ListItemIcon>
            <ListItemText
              primary="Valilik"
            />
          </ListItemButton>
        </List>
      );
    }

    return (<List dense={false}></List>);
  }

  const getLogo = () => {
    if (Capacitor.isNativePlatform()) {
      const logoSrc = `/assets/logo-${organization}.png`;
      return (
        <Logo
            disabledLink={true}
            center={true}
            src={logoSrc}
            sx={{
              position: 'fixed',
              top: { xs: 16, sm: 24, md: 40 },
              left: { xs: 16, sm: 24, md: 40 },
            }}
          />
      )
    }

    return (
      <Logo
        disabledLink={true}
        center={true}
        src='/assets/logo.png'
        sx={{
          position: 'fixed',
          top: { xs: 16, sm: 24, md: 40 },
          left: { xs: 16, sm: 24, md: 40 },
        }}
      />)
  }

  const getDialog = () => {
    if (Capacitor.isNativePlatform()) {
      return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={open}
          >
            <DialogTitle>
            <IconButton
              color="inherit"
              disabled={isStageOne}
              sx={{ mr: 1, mb: 0.5 }}
              onClick={goStageOne}
            >
              <ArrowBackIcon></ArrowBackIcon>
            </IconButton>
              { isStageOne ? 'İl Seçiniz' : 'Kurum Seçiniz' }
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Box sx={{ width: '100%', height: 400, bgcolor: 'background.paper', position: 'relative' }}>
                  <Slide direction="right" in={isStageOne} onExited={handleStageTwo} mountOnEnter unmountOnExit>
                    <List dense={false}>
                      <ListItemButton onClick={() => handleStageOne("cankiri")}>
                        <ListItemIcon>
                          <PlaceIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="Çankırı"
                        />
                      </ListItemButton>
                      <ListItemButton onClick={() => handleStageOne("erzincan")}>
                        <ListItemIcon>
                          <PlaceIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="Erzincan"
                        />
                      </ListItemButton>
                    </List>
                  </Slide>
                  <Slide direction="left" in={isStageTwo} mountOnEnter unmountOnExit>
                    {getStageTwoList()}
                  </Slide>
                  <Stepper sx={{ position: 'absolute', bottom: '20px', width: '100%' }} activeStep={getStage()} alternativeLabel>
                    <Step key={'İl Seçin'}>
                      <StepLabel>{'İl Seçin'}</StepLabel>
                    </Step>
                    <Step key={'Kurum Seçin'}>
                      <StepLabel>{'Kurum Seçin'}</StepLabel>
                    </Step>
                  </Stepper>
                </Box>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        );
    }

    return <></>;
  }

  return (
    <>
      {pageLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: '100%' }}>
          <CircularProgress color='inherit' />
        </Box>
      ) : (
        <>
          <Helmet>
          <title> Oturum Aç </title>
          </Helmet>

          <StyledRoot>
          
            <Container maxWidth="sm">

              {getDialog()}
            
              <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>

                {getLogo()}

                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
                  <Grid container item xs={12}>
                    <Grid item xs={12} sx={{ mt: 3 }}>
                      <TextField
                        autoComplete="Kullanıcı Adı"
                        name="userName"
                        required
                        fullWidth
                        id="userName"
                        label="Kullanıcı Adı"
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 5 }}>
                      <TextField
                        autoComplete="Şifre"
                        name="password"
                        required
                        fullWidth
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        label="Şifre"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    { formError ? <Grid item xs={12} sx={{ mt: 3 }}>
                        <Alert severity="error">Hatalı kullanıcı adı veya şifre</Alert>
                    </Grid> : <></>}
                    <Grid item xs={12} sx={{ mt: 5 }}>
                      <Button fullWidth size='large' variant="contained" type="submit">{loading ? <CircularProgress sx={{ width: '50', color: 'white'}}></CircularProgress> : <>Giriş</>}</Button>
                    </Grid>
                  </Grid>
                </Box>
              </StyledContent>
            </Container>
          </StyledRoot>
        </> 
      )}
      
    </>
  );
}
