import React, { useState, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import UserService from '../../services/UserService';
import Alert from '@mui/material/Alert';

export default function UserPasswordChange({ updateWithUserName = false, updateUser = {}, onUpdateComplete}) {
  const [formError, setFormError] = useState(false);
  const oldInput = useRef(null);
  const newInput = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormError(false);

    const data = new FormData(event.currentTarget);

    const body = {
      oldPassword: data.get('oldPassword'),
      newPassword: data.get('newPassword')
    };

    if (updateWithUserName) {
      body.oldPassword = null;
      body.userName = updateUser.userName;
    }

    try {
      await UserService.updateUserPassword(body);
      if (updateWithUserName) {
        onUpdateComplete();
      } else {
        clearInputs();
      }
    } catch(error) {
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Şifre değiştirilemedi, lütfen tekrar deneyiniz');
      }
      setFormError(true);
    }
  };

  const clearInputs = () => {
    oldInput.current.value = "";
    newInput.current.value = "";
  }

  return (
    <Container component="main" maxWidth="sm" sx={{backgroundColor: "#F9FAFB"}}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 6,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Şifre Değiştir
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3, width: '80%' }}>
          <Grid container spacing={2}>
            {updateWithUserName ? (null) : (
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  inputRef={oldInput}
                  name="oldPassword"
                  label="Eski Şifre"
                  type="password"
                  id="oldPassword"
                  autoComplete="Eski Şifre"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                inputRef={newInput}
                name="newPassword"
                label="Yeni Şifre"
                type="password"
                id="newPassword"
                autoComplete="Yeni Şifre"
              />
            </Grid>
          </Grid>
          { formError ? <Grid item xs={12} sx={{ mt: 3 }}>
              <Alert severity="error">{errorMessage ? errorMessage : 'Şifre değiştirilemedi, lütfen tekrar deneyiniz'}</Alert>
          </Grid> : <></>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, minHeight:'46px !important'}}
          >
            Kaydet
          </Button>
        </Box>
      </Box>
    </Container>
  );
}