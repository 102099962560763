import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//

import { useContext } from 'react';
import LoginPage from './pages/components/LoginPage';
import Dashboard from './pages/components/Dashboard';
import Page404 from './pages/components/Page404';
import UserRegister from './pages/components/UserRegister';
import Users from './pages/components/Users';
import Units from './pages/components/Units';
import Assignment from './pages/components/Assignment';
import UserPasswordChange from './pages/components/UserPasswordChange';
import { UserContext } from './App';
import Cookie from './utils/cookie';
// ----------------------------------------------------------------------

const ProtectedRoute = ({ token, user, authList, children }) => {
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  if (user) {
    if (!authList.includes(user.role)) {
      return <Navigate to="/404" replace />;
    }
  }

  return children;
};

export default function Router() {
  const { user } = useContext(UserContext);

  const routes = useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to="/login" />, index: true }
      ]
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/tasks" />, index: true },
        { 
          path: 'tasks',
          element: <ProtectedRoute token={Cookie.getCookie('access_token')} user={user} authList={[ "ROLE_MANAGER", "ROLE_USER", "ROLE_ADMIN", "ROLE_TOWN_MANAGER"]}><Dashboard /></ProtectedRoute>
         },
      ],
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/register" />, index: true },
        { path: 'register', element: <ProtectedRoute token={Cookie.getCookie('access_token')} user={user} authList={["ROLE_ADMIN", "ROLE_MANAGER"]}><UserRegister  /></ProtectedRoute> },
      ],
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/users" />, index: true },
        { path: 'users', element: <ProtectedRoute token={Cookie.getCookie('access_token')} user={user} authList={["ROLE_ADMIN", "ROLE_MANAGER"]}><Users /></ProtectedRoute> },
      ],
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/units" />, index: true },
        { path: 'units', element: <ProtectedRoute token={Cookie.getCookie('access_token')} user={user} authList={["ROLE_ADMIN"]}><Units /></ProtectedRoute> },
      ],
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/assignment" />, index: true },
        { path: 'assignment', element: <ProtectedRoute token={Cookie.getCookie('access_token')} user={user} authList={["ROLE_MANAGER", 'ROLE_ADMIN', "ROLE_TOWN_MANAGER"]}><Assignment /></ProtectedRoute> },
      ],
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/change-password" />, index: true },
        { path: 'change-password', element: <ProtectedRoute token={Cookie.getCookie('access_token')} authList={["ROLE_ADMIN", "ROLE_MANAGER", "ROLE_USER", "ROLE_TOWN_MANAGER"]}><UserPasswordChange /></ProtectedRoute> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
