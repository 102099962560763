import { getConfig } from '../config';
import { message } from 'antd';
import { Buffer } from 'buffer';
import Cookie from '../utils/cookie';
import { getAxiosInstance } from '../utils/axios';

class UserService {
  static async getUsers() {
    return await getAxiosInstance().get(`${await getConfig()}/admin-dashboard/v0/users`)
      .then(response => {
        if (response.status === 200) {
            return response.data;
        }
      })
      .catch(error => {
        return error;
      });
  }

  static async getUsersByPage(paginationModel, body, sort) {
    const params = {
      params: {
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
        sort: sort
      }
    }
    
    return await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/users`, body, params)
      .then(response => {
        if (response.status === 200) {
            return response.data;
        }
      })
      .catch(error => {
        return error;
      });
  }


  static async getManagers() {
    return await getAxiosInstance().get(`${await getConfig()}/admin-dashboard/v0/managers`)
      .then(response => {
        if (response.status === 200) {
            return response.data;
        }
      })
      .catch(error => {
        return error;
      });
  }

  static async login(body) {
    return await getAxiosInstance()
      .post(`${await getConfig()}/admin-dashboard/v0/user/login`, body)
      .then(async (response) => {
        if (response.status === 200) {
          const form = new FormData();
          const role = response.data.role;
          const userName = response.data.userName;
          const fullName = response.data.fullName;

          form.append("grant_type", "password");
          form.append("username", body.userName);
          form.append("password", body.password);
          form.append("role", role.name);

          const encoded = Buffer.from(response.data.oauthUsername + ':' + response.data.oauthPassword).toString('Base64');
          return await getAxiosInstance().post(`${await getConfig()}/oauth/token`, form, {
            auth: {
                username: response.data.oauthUsername,
                password: response.data.oauthPassword
            },
            headers: { "content-type": "multipart/form-data" }
          })
              .then(async (response) => {
                const userContext = {
                  fullName: fullName,
                  userName: userName,
                  role: role.name,
                  access_token: response.data.access_token,
                };

                await Cookie.login(response, encoded);

                message.success({
                    content: "Giriş Başarılı",
                    style: { marginTop: "100px" },
                });
                return userContext;
              })
              .catch(error => {
                  message.error({
                    content: "Giriş Başarısız!",
                    style: { marginTop: "100px" },
                  });
                  return error;
              })
        }
      })
      .catch(error => {
          message.error({
            content: "Giriş Başarısız!",
            style: { marginTop: "100px" },
          });
          return error;
      });
  }

  static async logout(platform) {
    const auth = {
      params: {
        platform: platform
      }
    };
  
    return await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/user/logout`, {}, auth)
      .then(response => {
        if (response.status === 200) {
            return response.data;
        }
      })
      .catch(error => {
        return error;
      });
  }

  static async register(body){
    await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/user/register`, body)
      .then(response => {
        if (response.status === 200) {
          message.success({
            content: "Kayıt Başarılı!",
            style: { marginTop: "100px" },
          });
        }
      })
      .catch(error => {
        message.error({
          content: "Kayıt Başarısız!",
          style: { marginTop: "100px" },
        });
        return error;
      });
  }

  static async updateUser(body) {
    return await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/user/update`, body)
      .then(response => {
        if (response.status === 200) {
          message.success({
            content: "Güncelleme Başarılı!",
            style: { marginTop: "100px" },
          });
          return true;
        }
      })
      .catch(error => {
        message.error({
          content: "Güncelleme Başarısız!",
          style: { marginTop: "100px" },
        });
        return error;
      });
  } 

  static async deleteUser(body) {
    return await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/user/delete`, body)
      .then(response => {
        if (response.status === 200) {
          message.success({
            content: "Güncelleme Başarılı!",
            style: { marginTop: "100px" },
          });
          return true;
        }
      })
      .catch(error => {
        message.error({
          content: "Güncelleme Başarısız!",
          style: { marginTop: "100px" },
        });
        return error;
      });
  } 

  static async updateUserPassword(body) {
    await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/user/update-password`, body)
      .then(response => {
        if (response.status === 200) {
          message.success({
            content: "Güncelleme Başarılı!",
            style: { marginTop: "100px" },
          });
        }
      })
      .catch(error => {
        message.error({
          content: "Şifre Değiştirme Başarısız!",
          style: { marginTop: "100px" },
        });
        return error;
      });
  }

  static async getUserDetails() {
    return await getAxiosInstance().get(`${await getConfig()}/admin-dashboard/v0/user/details`)
      .then(response => {
        if (response.status === 200) {
            return response.data;
        }
      })
      .catch(error => {
        return error;
      });
  }

  static async refreshToken() {
    const form = new FormData();

    const decoded = Buffer.from(await Cookie.getCookie('clientId'), 'base64');
    const splitted = decoded.toString('ascii').split(':');

    form.append("grant_type", "refresh_token");
    form.append("refresh_token", await Cookie.getCookie('refresh_token'));
    return await getAxiosInstance().post(`${await getConfig()}/oauth/token`, form, {
      auth: {
          username: splitted[0],
          password: splitted[1]
      },
      headers: { "content-type": "multipart/form-data" }
    })
        .then(async (response) => {
          await Cookie.refreshToken(response);
          return response.data.access_token;
        })
        .catch(error => {
          return error;
        })
  }
}

export default UserService;