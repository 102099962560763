import { getConfig } from '../config';
import { message } from 'antd';
import { getAxiosInstance } from '../utils/axios';

class UnitService {
  static async getUnits() {
    return await getAxiosInstance().get(`${await getConfig()}/admin-dashboard/v0/organizations`)
        .then(response => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch(error => {
          return error;
        });
  }

  static async getUnitUsers(id) {
    return await getAxiosInstance().get(`${await getConfig()}/admin-dashboard/v0/organization/${id}/users`)
        .then(response => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch(error => {
          return error;
        });
  }

  static async createOrganization(body) {
    return await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/organization/create`, body)
      .then(response => {
        if (response.status === 200) {
          message.success({
            content: "Kayıt Başarılı!",
            style: { marginTop: "100px" },
          });
          return response.data;
        }
      })
      .catch(error => {
        message.error({
          content: "Kayıt Başarısız!",
          style: { marginTop: "100px" },
        });
        return error;
      });
  }

  static async updateOrganization(id, body) {
    return await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/organization/${id}/update`, body)
      .then(response => {
        if (response.status === 200) {
          message.success({
            content: "Güncelleme Başarılı!",
            style: { marginTop: "100px" },
          });
          return response.data;
        }
      })
      .catch(error => {
        message.error({
          content: "Güncelleme Başarısız!",
          style: { marginTop: "100px" },
        });
        return error;
      });
  }

  static async deleteOrganization(id) {
    return await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/organization/${id}/delete`, {})
      .then(response => {
        if (response.status === 200) {
          message.success({
            content: "Güncelleme Başarılı!",
            style: { marginTop: "100px" },
          });
          return response.data;
        }
      })
      .catch(error => {
        message.error({
          content: "Güncelleme Başarısız!",
          style: { marginTop: "100px" },
        });
        return error;
      });
  }
}

export default UnitService;