import { getConfig } from '../config';
import { getAxiosInstance } from '../utils/axios';

class RoleService {
  
  static async getRoles() {
    return await getAxiosInstance().get(`${await getConfig()}/admin-dashboard/v0/roles`)
        .then(response => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch(error => {
          return error;
        });
  }
}

export default RoleService;