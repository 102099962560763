// component
import PeopleIcon from '@mui/icons-material/People';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TaskIcon from '@mui/icons-material/Task';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ApartmentIcon from '@mui/icons-material/Apartment';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Görevler',
    path: '/dashboard/tasks',
    icon: <TaskIcon></TaskIcon>,
    roles: [ 'ROLE_USER', 'ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_TOWN_MANAGER' ]
  },
  {
    title: 'Kullanıcı Ekle',
    path: '/dashboard/register',
    icon: <PersonAddIcon></PersonAddIcon>,
    roles: [ 'ROLE_ADMIN', 'ROLE_MANAGER' ]
  },
  {
    title: 'Kullanıcılar',
    path: '/dashboard/users',
    icon: <PeopleIcon></PeopleIcon>,
    roles: [ 'ROLE_ADMIN', 'ROLE_MANAGER' ]
  },
  {
    title: 'Birimler',
    path: '/dashboard/units',
    icon: <ApartmentIcon></ApartmentIcon>,
    roles: [ 'ROLE_ADMIN']
  },
  {
    title: 'Görev Oluştur',
    path: '/dashboard/assignment',
    icon: <AssignmentIndIcon></AssignmentIndIcon>,
    roles: [ 'ROLE_MANAGER', 'ROLE_ADMIN' ]
  },
  {
    title: 'Görev Talep Et',
    path: '/dashboard/assignment',
    icon: <AssignmentIndIcon></AssignmentIndIcon>,
    roles: [ 'ROLE_TOWN_MANAGER' ]
  }
];

export default navConfig;
