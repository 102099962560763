import { useState, useContext } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import { UserContext } from '../../../App';
import { useNavigate } from 'react-router-dom';
import BackgroundLetterAvatars from '../../../components/avatar/BackgroundLetterAvatars';
import Cookie from '../../../utils/cookie';
import UserService from '../../../services/UserService';
import { Capacitor } from '@capacitor/core';
// ----------------------------------------------------------------------


export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const logOut = async () => {
    UserService.logout(Capacitor.getPlatform().toUpperCase())
      .then(async () => {
        await Cookie.logout();
        navigate('/login', { replace: true });
      });
  }

  const changePassword = () => {
    handleClose();
    navigate('/dashboard/change-password', { replace: true });
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <BackgroundLetterAvatars fullName={user ? user.fullName : ''} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user ? user.fullName : ''}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user ? user.userName : ''}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem key={'Şifre Değiştir'} onClick={changePassword}>
            Şifre Değiştir
          </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={logOut} sx={{ m: 1 }}>
          Çıkış Yap
        </MenuItem>
      </Popover>
    </>
  );
}
