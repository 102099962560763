import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography } from '@mui/material';
import {
  TaskList,
  StatusChart,
  UrgencyChart,
  TaskCount
} from '../../sections/@dashboard/app';
import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import TaskService from '../../services/TaskService';
import { UserContext } from '../../App';
import { useTheme } from '@mui/material/styles';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { subscribe, unsubscribe } from '../../utils/events';
import useResponsive from '../../hooks/useResponsive';

export default function DashboardAppPage() {
  const [tasks, setTasks] = useState({});
  const isInitialized = useRef(false);

  const [manager, setManager] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [townManager, setTownManager] = useState(false);
  const [taskFetchProps, setTaskFetchProps] = useState({
    page: 0,
    body: {},
    sort: 'createdAt;DESC'
  });

  const { user } = useContext(UserContext);
  const theme = useTheme();

  const refreshTask = (page, body, sort) => {
    const fetchProps = {
      page: page,
      body: body,
      sort: sort
    };

    setTaskFetchProps(fetchProps);
    fetchTasks(page, body, sort);
  }

  const fetchTasks = async (page, body, sort) => {
    const taskList = await TaskService.getTasks(page, body, sort);

    if (!taskList.isAxiosError) {
      setTasks(taskList);
    }
  }

  useEffect(() => { 
    if (user && !isInitialized.current) {
      if (user && user.role === "ROLE_MANAGER") {
        setManager(true);
        setAdmin(false);
        setTownManager(false);
      } else if (user && user.role === "ROLE_ADMIN") {
        setManager(false);
        setAdmin(true);
        setTownManager(false);
      } else if (user && user.role === "ROLE_TOWN_MANAGER") {
        setManager(false);
        setAdmin(false);
        setTownManager(true);
      }

      fetchTasks(0, {}, 'createdAt;DESC');
      isInitialized.current = true;
    }

    subscribe('notification-received', eventListener);
    subscribe('refresh-page', eventListener);
      
    return () => {
      unsubscribe('notification-received', eventListener);
      unsubscribe('refresh-page', eventListener);
    }
  }, [user]);

  const getDoneCount = () => {
    if (tasks && tasks.doneTasks) {
      return tasks.doneTasks;
    }

    return 0;
  }

  const getNotDoneCount = () => {
    if (tasks && tasks.notDoneTasks) {
      return tasks.notDoneTasks;
    }

    return 0;
  }


  const getUrgentCount = () => {
    if (tasks && tasks.highUrgencyNotDoneTasks) {
      return tasks.highUrgencyNotDoneTasks;
    }

    return 0;
  }

  const getUrgentChartData = () => {
    if (tasks && tasks.urgencyChart) {
      return tasks.urgencyChart;
    }

    return [];
  }

  const getStatusChartData = () => {
    if (tasks && tasks.statusChart) {
      return tasks.statusChart;
    }

    return [];
  }

  const getTaskCount = () => {
    if (tasks && tasks.taskCount) {
      return tasks.taskCount;
    }

    return 0;
  }

  const eventListener = useCallback(function (e) {
    handleRefresh();
  }, []);

  const handleRefresh = async () =>  {
    await fetchTasks(taskFetchProps.page, taskFetchProps.body, taskFetchProps.sort);
  }

  const lgUp = useResponsive('up', 'lg');

  return (
    <PullToRefresh onRefresh={handleRefresh} isPullable={!lgUp}>
      <Helmet>
        <title> Çankırı İl Özel İdaresi </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hoşgeldiniz
        </Typography>

        <Grid container spacing={3}>
          
          <Grid item xs={12} sm={6} md={3}>
            <TaskCount title="Toplam Görevler" color="info" total={getTaskCount()} icon={'ant-design:reconciliation-filled'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TaskCount title="Tamamlanmış Görevler" color="success" total={getDoneCount()} icon={'ant-design:reconciliation-filled'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TaskCount title="Tamamlanmamış Görevler" color="warning" total={getNotDoneCount()} icon={'ant-design:reconciliation-filled'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TaskCount title="Tamamlanmamış Aciliyeti Yüksek Görevler" color="error" total={getUrgentCount()} icon={'ant-design:reconciliation-filled'} />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <UrgencyChart
              title="Aciliyete Bağlı Görev Grafiği"
              chartData={getUrgentChartData()}
              chartColors={[
                theme.palette.success.main,
                '#ff9800',
                theme.palette.error.main,
              ]}
            />
            </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <StatusChart
              title="Duruma Bağlı Görev Grafiği"
              chartData={getStatusChartData()}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <TaskList
              tasks={tasks}
              fetchTasks={refreshTask}
              manager={manager}
              admin={admin}
              townManager={townManager}
              userName={user ? user.userName : ''}
            />
          </Grid>
        </Grid>
      </Container>
    </PullToRefresh>
  );
}