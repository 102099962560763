import Axios from 'axios';
import { getConfig } from '../config';
import { message } from 'antd';
import { getAxiosInstance } from '../utils/axios';

class TaskService {
  static async getTasks(page, body, sort) {
    const params = {
      params: {
        page: page,
        pageSize: 5,
        sort: sort
      }
    }
    
    return await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/tasks`, body, params)
      .then(response => {
        if (response.status === 200) {
            return response.data;
        }
      })
      .catch(error => {
        return error;
      });
  }

  static async createTask(formData) {
    const params = {
      headers: { "content-type": "multipart/form-data" },
    }

    return await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/task/create`, formData, params)
      .then(response => {
        if (response.status === 200) {
          message.success({
            content: "Kayıt Başarılı!",
            style: { marginTop: "100px" },
          });
          return response.data;
        }
      })
      .catch(error => {
        message.error({
          content: "Kayıt Başarısız!",
          style: { marginTop: "100px" },
        });
        return error;
      });
  }

  static async updateTask(id, body) {
    return await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/task/${id}/update`, body)
      .then(response => {
        if (response.status === 200) {
          message.success({
            content: "Güncelleme Başarılı!",
            style: { marginTop: "100px" },
          });
          return response.data;
        }
      })
      .catch(error => {
        message.error({
          content: "Güncelleme Başarısız!",
          style: { marginTop: "100px" },
        });
        return error;
      });
  }

  static async updateTaskStatus(id, body) {
    return await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/task/${id}/update-status`, body)
      .then(response => {
        if (response.status === 200) {
          message.success({
            content: "Güncelleme Başarılı!",
            style: { marginTop: "100px" },
          });
          return response.data;
        }
      })
      .catch(error => {
        message.error({
          content: "Güncelleme Başarısız!",
          style: { marginTop: "100px" },
        });
        return error;
      });
  }

  static async addAttachment(id, form) {
    const params = {
      headers: { "content-type": "multipart/form-data" },
    };

    const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

    Axios.interceptors.request.use(function(config) {
      // Concatenate base path if not an absolute URL
      if ( !isAbsoluteURLRegex.test(config.url) ) {
        config.url = '';
      }
    
      return config;
    });

    return await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/task/${id}/add-attachments`, form, params)
      .then(response => {
        if (response.status === 200) {
          message.success({
            content: "Güncelleme Başarılı!",
            style: { marginTop: "100px" },
          });
          return response.data;
        }

        message.error({
          content: "Güncelleme Başarısız!",
          style: { marginTop: "100px" },
        });
        throw new Error("Required");
      })
      .catch(error => {
        message.error({
          content: "Güncelleme Başarısız!",
          style: { marginTop: "100px" },
        });
        return error;
      });
  }

  static async deleteAttachment(id, attachmentId) {
    const params = {
      headers: { "content-type": "multipart/form-data" },
    };

    const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

    Axios.interceptors.request.use(function(config) {
      // Concatenate base path if not an absolute URL
      if ( !isAbsoluteURLRegex.test(config.url) ) {
        config.url = '';
      }
    
      return config;
    });

    return await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/task/${id}/attachment/${attachmentId}/delete`, {}, params)
      .then(response => {
        if (response.status === 200) {
          message.success({
            content: "Güncelleme Başarılı!",
            style: { marginTop: "100px" },
          });
          return response.data;
        }

        message.error({
          content: "Güncelleme Başarısız!",
          style: { marginTop: "100px" },
        });
        throw new Error("Status not 200");
      })
      .catch(error => {
        message.error({
          content: "Güncelleme Başarısız!",
          style: { marginTop: "100px" },
        });
        return error;
      });
  }

  static async assignTask(id, body) {
    const params = {
      headers: { "content-type": "multipart/form-data" },
    };

    return await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/task/${id}/assign`, body, params)
      .then(response => {
        if (response.status === 200) {
          message.success({
            content: "Güncelleme Başarılı!",
            style: { marginTop: "100px" },
          });
          return response.data;
        }

        message.error({
          content: "Güncelleme Başarısız!",
          style: { marginTop: "100px" },
        });
        throw new Error("Status not 200");
      })
      .catch(error => {
        let errorMessage = 'Güncelleme Başarısız!';

        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }

        message.error({
          content: errorMessage,
          style: { marginTop: "100px" },
        });
        return error;
      });
  }

  static async requestTask(formData) {
    const params = {
      headers: { "content-type": "multipart/form-data" },
    }

    return await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/task/request`, formData, params)
      .then(response => {
        if (response.status === 200) {
          message.success({
            content: "Kayıt Başarılı!",
            style: { marginTop: "100px" },
          });
          return response.data;
        }
      })
      .catch(error => {
        message.error({
          content: "Kayıt Başarısız!",
          style: { marginTop: "100px" },
        });
        return error;
      });
  }

  static async updateRequestedTask(id, body) {
    return await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/task/${id}/update-requested`, body)
      .then(response => {
        if (response.status === 200) {
          message.success({
            content: "Güncelleme Başarılı!",
            style: { marginTop: "100px" },
          });
          return response.data;
        }
      })
      .catch(error => {
        message.error({
          content: "Güncelleme Başarısız!",
          style: { marginTop: "100px" },
        });
        return error;
      });
  }

  static async deleteTask(id) {
    return await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/task/${id}/delete`, {})
      .then(response => {
        if (response.status === 200) {
          message.success({
            content: "Silme Başarılı!",
            style: { marginTop: "100px" },
          });
          return response.data;
        }
      })
      .catch(error => {
        message.error({
          content: "Silme Başarısız!",
          style: { marginTop: "100px" },
        });
        return error;
      });
  }
}

export default TaskService;