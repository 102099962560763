import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import Typography from '@mui/material/Typography';
import { STATUS_MAP, URGENCY_MAP } from '../../../locales/constants';

export default function TaskDetails({ task, manager, admin }) {

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 3,
          marginBottom: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <TaskOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Görev Detay
        </Typography>
        <Box sx={{ mt: 5, width: '100%' }} >
          <Grid container spacing={3}>
          <Grid item xs={6}>
              <TextField
                value={STATUS_MAP[task.status]}
                InputProps={{
                  readOnly: true,
                }}   
                autoComplete="Durum"
                name="status"
                fullWidth
                id="status"
                label="Durum"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={URGENCY_MAP[task.urgency]}
                InputProps={{
                  readOnly: true,
                }}   
                autoComplete="Aciliyet"
                name="urgency"
                fullWidth
                id="urgency"
                label="Aciliyet"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={task.subject}
                InputProps={{
                  readOnly: true,
                }}   
                autoComplete="Konu"
                name="subject"
                fullWidth
                id="subject"
                label="Konu"
                multiline
                rows={3}
                maxRows={3}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={task.description}
                InputProps={{
                  readOnly: true,
                }}   
                fullWidth
                id="description"
                label="Açıklama"
                name="description"
                autoComplete="Açıklama"
                multiline
                rows={3}
                maxRows={3}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={task.statusMessage}
                InputProps={{
                  readOnly: true,
                }}   
                fullWidth
                id="statusMessage"
                label="Ek Bilgi"
                name="statusMessage"
                autoComplete=""
                multiline
                rows={3}
                maxRows={3}
              />
            </Grid>
            {task.status === "RETURNED" || task.status === "SENT_BACK" || task.status === "NOT_APPROVED" ? (
            <Grid item xs={12}>
              <TextField
                value={task.returnReason}
                InputProps={{
                  readOnly: true,
                }}   
                fullWidth
                id="returnReason"
                label={task.status === "NOT_APPROVED" ? "Uygun Görülmeme Nedeni" : "İade Nedeni"}
                name="returnReason"
                autoComplete=""
                multiline
                rows={3}
                maxRows={3}
              />
            </Grid>) : (<></>)}
            {task.status === "DENIED" || task.status === "DONE" ? (
            <Grid item xs={12}>
              <TextField
                value={task.confirmationMessage}
                InputProps={{
                  readOnly: true,
                }}   
                fullWidth
                id="confirmationMessage"
                label={task.status === "DENIED" ? "Ret Nedeni" : "Onay Mesajı"}
                name="confirmationMessage"
                autoComplete=""
                multiline
                rows={3}
                maxRows={3}
              />
            </Grid>) : (<></>)}
            {task.status === 'REQUESTED' ? (
              <Grid item xs={12}>    
                <TextField
                  value={task.requestingFullName}
                  id="requestingFullName"
                  name="requestingFullName"
                  label="Talep Eden Kullanıcı"
                  InputProps={{
                    readOnly: true,
                  }}                
                  fullWidth
                >
                </TextField>
              </Grid>
            ) : (
              <>
              {manager || admin ?
                (<Grid item xs={12}>    
                  <TextField
                    value={task.assigneeFullName}
                    id="assigneeUserName"
                    name="assigneeUserName"
                    label="Atanan Kullanıcı"
                    InputProps={{
                      readOnly: true,
                    }}                
                    fullWidth
                  >
                  </TextField>
                </Grid>) : (<></>)}
              {admin ?
                (<Grid item xs={12}>    
                  <TextField
                    value={task.organizationName}
                    id="organizationName"
                    name="organizationName"
                    label="Atanan Birim"
                    InputProps={{
                      readOnly: true,
                    }}                
                    fullWidth
                  >
                  </TextField>
                </Grid>) : (<></>)}
              </>
            )}
            
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}