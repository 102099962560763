import { getConfig } from '../config';
import { getAxiosInstance } from '../utils/axios';

class NotificationService {

  static async getNotifications() {
    return await getAxiosInstance().get(`${await getConfig()}/admin-dashboard/v0/notifications`)
      .then(response => {
        if (response.status === 200) {
            return response.data;
        }
      })
      .catch(error => {
        return error;
      });
  }

  static async createUserToken(body) {
    return await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/notification/token`, body)
      .then(response => {
        if (response.status === 200) {
            return response.data;
        }
      })
      .catch(error => {
        return error;
      });
  }

  static async deleteNotification(id) {
    return await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/notification/${id}/delete`, {})
      .then(response => {
        if (response.status === 200) {
            return response.data;
        }
      })
      .catch(error => {
        return error;
      });
  }

  static async markReadNotifications(id) {
    return await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/notification/read`, {})
      .then(response => {
        if (response.status === 200) {
            return response.data;
        }
      })
      .catch(error => {
        return error;
      });
  }

  static async markReadNotidication(id) {
    return await getAxiosInstance().post(`${await getConfig()}/admin-dashboard/v0/notification/${id}/read`, {})
      .then(response => {
        if (response.status === 200) {
            return response.data;
        }
      })
      .catch(error => {
        return error;
      });
  }
}

export default NotificationService;