export const STATUS_MAP = {
  ASSIGNED: 'Atandı',
  IN_PROGRESS: 'Çalışılıyor',
  RETURNED: 'İade Edildi',
  SENT_BACK: 'Geri Gönderildi',
  WAITING_APPROVE: 'Onay Bekliyor',
  DENIED: 'Reddedildi',
  DONE: 'Tamamlandı',
  NOT_APPROVED: 'Uygun Görülmedi',
  REQUESTED: 'Talep Edildi',
}

export const URGENCY_MAP = {
  LOW: 'Düşük Aciliyet',
  MEDIUM: 'Orta Aciliyet',
  HIGH: 'Yüksek Aciliyet',
};

export const ROLE_MAP = {
  ROLE_USER: 'KULLANICI',
  ROLE_MANAGER: 'YÖNETİCİ',
  ROLE_ADMIN: 'ADMIN',
  ROLE_TOWN_MANAGER: 'VALİ',
};

export const ROLE_LOWER_MAP = {
  ROLE_USER: 'Kullanıcı',
  ROLE_MANAGER: 'Yönetici',
  ROLE_ADMIN: 'Admin',
  ROLE_TOWN_MANAGER: 'Vali',
}

export const STATUS_COLOR_MAP = {
  ASSIGNED: 'grey_secondary',
  IN_PROGRESS: 'blue_secondary',
  RETURNED: 'warning',
  SENT_BACK: 'warning',
  WAITING_APPROVE: 'info',
  DENIED: 'error',
  DONE: 'success',
  NOT_APPROVED: 'warning',
  REQUESTED: 'info'
}

export const STATUS_ICON_MAP = {
  ASSIGNED: 'https://api.iconify.design/carbon/task-add.svg?color=white',
  IN_PROGRESS: 'https://api.iconify.design/tabler/progress.svg?color=white',
  RETURNED: 'https://api.iconify.design/ph/key-return.svg?color=white',
  SENT_BACK: 'https://api.iconify.design/ph/key-return.svg?color=white',
  WAITING_APPROVE: 'https://api.iconify.design/streamline/interface-page-controller-loading-1-progress-loading-load-wait-waiting.svg?color=white',
  DENIED: 'https://api.iconify.design/mdi/cancel-circle-outline.svg?color=white',
  DONE: 'https://api.iconify.design/material-symbols/check-circle.svg?color=white',
  NOT_APPROVED: 'https://api.iconify.design/mdi/note-off.svg?color=white',
  REQUESTED: 'https://api.iconify.design/material-symbols/add-task.svg?color=white'
}

export const URGENCY_COLOR_MAP = {
  LOW: 'success',
  MEDIUM: 'warning',
  HIGH: 'error',
};

export const URGENCY_ICON_MAP = {
  LOW: 'https://api.iconify.design/iconoir/priority-down.svg?color=%232e7d32',
  MEDIUM: 'https://api.iconify.design/iconoir/medium-priority.svg?color=%23d84315',
  HIGH: 'https://api.iconify.design/iconoir/priority-up.svg?color=%23b71c1c',
};

export const formatDate = (date) => {
  if (!date) {
    return 'Eklenmemiş';
  }
  return date.split("-").reverse().join('/');
}

export const getDateColor = (date, theme) => {
  if (!date) {
    return theme.palette['secondary'].darker
  }

  const totalDay = calcDate(date, new Date());

  if (totalDay > 5 ) {
    return theme.palette['success'].darker
  } else if (totalDay > 1 && totalDay < 5) {
    return theme.palette['warning'].darker
  } else if (totalDay <= 1) {
    return theme.palette['error'].darker
  }
}

function calcDate(date1, date2) {

  const dt_date1 = new Date(date1);
  const dt_date2 = date2;

  const date1_time_stamp = dt_date1.getTime();
  const date2_time_stamp = dt_date2.getTime();

  let calc;
  let isNegative;

  if (date1_time_stamp > date2_time_stamp) {
      calc = new Date(date1_time_stamp - date2_time_stamp);
      isNegative = false;
  } else {
      calc = new Date(date2_time_stamp - date1_time_stamp);
      isNegative = true;
  }

  const calcFormatTmp = calc.getDate() + '-' + (calc.getMonth() + 1) + '-' + calc.getFullYear();
  const calcFormat = calcFormatTmp.split("-");
  const days_passed = Number(Math.abs(calcFormat[0]) - 1);
  const months_passed = Number(Math.abs(calcFormat[1]) - 1);
  const years_passed = Number(Math.abs(calcFormat[2]) - 1970);
  const total_days = (years_passed * 365) + (months_passed * 30.417) + days_passed;

  return isNegative ? Math.round(total_days) * -1 : Math.round(total_days);
}