import React, { useEffect, useState, useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import UserService from '../../../services/UserService';
import Alert from '@mui/material/Alert';
import UnitService from '../../../services/UnitService'
import RoleService from '../../../services/RoleService'
import { ROLE_LOWER_MAP } from '../../../locales/constants';
import { UserContext } from '../../../App';

export default function UserUpdate({ userData, updateComplete }) {
  const { user } = useContext(UserContext);

  const [isAdmin, setIsAdmin] = useState(false);
  const [formError, setFormError] = useState(false);
  const [units, setUnits] = useState([]);
  const [roles, setRoles] = useState([]);
  const [unitDisabled, setUnitDisabled] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        const units = await UnitService.getUnits();
        const roles = await RoleService.getRoles();

        if (!units.isAxiosError) {
          setUnits(units);
        }

        if (!roles.isAxiosError) {
          setRoles(roles);
        }

        if (userData.role && userData.role.name) {
          const isDisabled = userData.role.name === 'ROLE_ADMIN' || userData.role.name === 'ROLE_TOWN_MANAGER';
          setUnitDisabled(isDisabled);
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (user && user.role) {
      if (user.role === 'ROLE_ADMIN') {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }

    fetch();
  }, []);

  const handleSubmit = async (event) => {
    setFormError(false);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const body = {
      userName: userData.userName,
      password: data.get('password'),
      name: data.get('name'),
      surname: data.get('surname'),
      roleName: isAdmin ? data.get('role') : userData.role.name,
    };

    if (!unitDisabled) {
      body.organizationId = isAdmin ? data.get('unit') : userData.organization.id ;
    }

    try {
      await UserService.updateUser(body);
      updateComplete();
    } catch (error) {
      console.log(error);
      setFormError(true);
    }
  };

  return (
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 5,
            marginBottom: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Kullanıcı Güncelle
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 5 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="Ad"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Ad"
                  defaultValue={userData.name}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="surname"
                  label="Soyad"
                  name="surname"
                  defaultValue={userData.surname}
                  autoComplete="Soyad"
                />
              </Grid>
              {isAdmin ? (
                <Grid item xs={12}>    
                  <TextField
                    id="role"
                    name="role"
                    select
                    label="Rol"
                    required
                    fullWidth
                    defaultValue={userData.role ? userData.role.name : ''}
                    onChange={(event) => {
                      setUnitDisabled(event.target.value === "ROLE_ADMIN" || event.target.value === "ROLE_TOWN_MANAGER");
                    }}
                  >
                    {roles.map(role => <MenuItem value={role.name}>{ROLE_LOWER_MAP[role.name]}</MenuItem>)}
                  </TextField>
                </Grid>) : (<></>)
              }
              {unitDisabled || !isAdmin ? (<></>) : (
                <Grid item xs={12}>    
                  <TextField
                    id="unit"
                    name="unit"
                    select
                    label="Birim"
                    required
                    fullWidth
                    defaultValue={userData.organization ? userData.organization.id : ''}
                  >
                    {units.map(unit => <MenuItem value={unit.id}>{unit.name}</MenuItem>)}
                  </TextField>
                </Grid>
              )}
            </Grid>
            { formError ? <Grid item xs={12} sx={{ mt: 3 }}>
                <Alert severity="error">Kayıt başarısız, lütfen daha sonra tekrar deneyin</Alert>
            </Grid> : <></>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, minHeight:'46px !important'}}
            >
              Güncelle
            </Button>
          </Box>
        </Box>
      </Container>
  );
}