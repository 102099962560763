import React, { useEffect, useRef, useState, useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import UserService from '../../services/UserService';
import Alert from '@mui/material/Alert';
import UnitService from '../../services/UnitService'
import RoleService from '../../services/RoleService'
import { ROLE_LOWER_MAP } from '../../locales/constants';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import { UserContext } from '../../App';

export default function UserRegister() {
  const { user } = useContext(UserContext);

  const userNameInput = useRef(null);
  const passwordInput = useRef(null);
  const nameInput = useRef(null);
  const surnameInput = useRef(null);
  const roleInput = useRef(null);
  const unitInput = useRef(null);

  
  const [isAdmin, setIsAdmin] = useState(false);
  const [formError, setFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [units, setUnits] = useState([]);
  const [roles, setRoles] = useState([]);
  const [showPassword, setShowPassword] = React.useState(false);
  const [unitDisabled, setUnitDisabled] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        const units = await UnitService.getUnits();
        const roles = await RoleService.getRoles();
        
        if (!units.isAxiosError) {
          setUnits(units);
        }

        if (!roles.isAxiosError) {
          setRoles(roles);
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (user && user.role) {
      if (user.role === 'ROLE_ADMIN') {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }

    fetch();
  }, [isAdmin, user]);

  const handleSubmit = async (event) => {
    setFormError(false);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const body = {
      userName: data.get('userName'),
      password: data.get('password'),
      name: data.get('name'),
      surname: data.get('surname'),
      roleName: data.get('role'),
      organizationId: data.get('unit')
    };

    try {
      await UserService.register(body);
      clearInputs();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Kayıt başarısız, lütfen daha sonra tekrar deneyiniz');
      }
      setFormError(true);
    }
  };

  const clearInputs = () => {
    userNameInput.current.value = "";
    passwordInput.current.value = "";
    nameInput.current.value = "";
    surnameInput.current.value = "";

    if (isAdmin) {
      roleInput.current.value = "";
    }

    if (!unitDisabled && isAdmin) {
      unitInput.current.value = "";
    }
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getRoles = () => {
    if (isAdmin) {
      return roles;
    }

    return roles.filter((e) => e.name === 'ROLE_USER');
  }
  
  return (
    <Container component="main" maxWidth="sm" sx={{backgroundColor: "#F9FAFB"}}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Kullanıcı Ekle
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="Ad"
                name="name"
                required
                fullWidth
                id="name"
                label="Ad"
                autoFocus
                inputRef={nameInput}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="surname"
                label="Soyad"
                name="surname"
                autoComplete="Soyad"
                inputRef={surnameInput}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="userName"
                label="Kullanıcı Adı"
                name="userName"
                autoComplete="userName"
                inputRef={userNameInput}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>
            <Grid item xs={12}> 
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="password">Şifre</InputLabel>
                <OutlinedInput
                  id="password"
                  name="password"
                  required
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  inputRef={passwordInput}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Şifre"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>    
              <TextField
                id="role"
                name="role"
                select
                label="Rol"
                defaultValue=""
                required
                fullWidth
                inputRef={roleInput}
                onChange={(event) => {
                  setUnitDisabled(event.target.value === "ROLE_ADMIN" || event.target.value === "ROLE_TOWN_MANAGER");
                }}
              >
                {getRoles().map(role => <MenuItem value={role.name}>{ROLE_LOWER_MAP[role.name]}</MenuItem>)}
              </TextField>
            </Grid>
            {(unitDisabled || !isAdmin) ? <></> : (
              <Grid item xs={12}>    
                <TextField
                  id="unit"
                  name="unit"
                  select
                  label="Birim"
                  defaultValue=""
                  required
                  fullWidth
                  inputRef={unitInput}
                  
                >
                  {units.map(unit => <MenuItem value={unit.id}>{unit.name}</MenuItem>)}
                </TextField>
              </Grid>
            )}
          </Grid>
          { formError ? <Grid item xs={12} sx={{ mt: 3 }}>
              <Alert severity="error">{errorMessage ? errorMessage : 'Kayıt başarısız, lütfen daha sonra tekrar deneyiniz'}</Alert>
          </Grid> : <></>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, minHeight:'46px !important'}}
          >
            Kaydet
          </Button>
        </Box>
      </Box>
    </Container>
  );
}