import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp } from '@capacitor/app';
import { publish } from './utils/events';

export const UserContext = React.createContext(null);

function App() {
  const [user, setUser] = useState(null);
  const isInitialized = useRef(false);

  const setStatusBarStyleLight = async () => {
    await StatusBar.setStyle({ style: Style.Light });
  };

  const setStatusBarColor = async () => {
    await StatusBar.setBackgroundColor({ color: '#F9FAFB' });
  };

  const setStatusBarOverlay = async () => {
    await StatusBar.setOverlaysWebView({overlay:false});
  };

  const initializeApp = async () => {
    if(Capacitor.isNativePlatform()) {
      try {
        setStatusBarOverlay();
        setStatusBarColor();
        setStatusBarStyleLight();
      } catch (e) {
        console.log('Status bar not competible');
      }

      CapacitorApp.addListener('backButton', async ({canGoBack}) => {
        if(!canGoBack){
          await CapacitorApp.exitApp();
        } else {
          window.history.back();
        }
      });
    }

    CapacitorApp.addListener("appStateChange", ({ isActive }) => {
      if (isActive) {
        publish('refresh-page');
      }
    });
    
  }

  const removeListeners = async () => {
    await CapacitorApp.removeAllListeners();
  }
  
  useEffect(() => {
    if (!isInitialized.current) {
      initializeApp();
      isInitialized.current = true;
    }

    return () => {
      removeListeners();
    };
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <Router />
          </ThemeProvider>
        </UserContext.Provider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;