import * as React from 'react';
import { 
  DataGrid, 
  trTR, 
  getGridSingleSelectOperators, 
  GridToolbarContainer,
  GridToolbarFilterButton, } from '@mui/x-data-grid';
import { useEffect, useState, useContext } from 'react';
import UserService from '../../services/UserService';
import { ROLE_MAP } from '../../locales/constants';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import PeopleIcon from '@mui/icons-material/People';
import Button from '@mui/material/Button';
import { styled,alpha  } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import UserUpdate from '../../sections/@dashboard/app/UserUpdate';
import UserPasswordChange from '../../pages/components/UserPasswordChange';
import Divider from "@mui/material/Divider";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Iconify from '../../components/iconify';
import PullToRefresh from 'react-simple-pull-to-refresh';
import useResponsive from '../../hooks/useResponsive';
import UnitService from '../../services/UnitService';
import RoleService from '../../services/RoleService';
import { UserContext } from '../../App';

export default function Users() {
  const { user } = useContext(UserContext);

  const orgFilterOperators = () => {
    if (user && user.role === 'ROLE_ADMIN') {
      return getGridSingleSelectOperators()
        .filter(({ value }) =>
          ['is' ].includes(value),
        );
    }

    return null;
  };

  const roleFilterOperators = getGridSingleSelectOperators()
    .filter(({ value }) =>
      ['is' ].includes(value),
    );


  const [users, setUsers] = useState([]);
  const [units, setUnits] = useState(null);
  const [roles, setRoles] = useState(null);

  const [sortModel, setSortModel] = useState('createdAt;DESC');
  const [filterModel, setFilterModel] = useState({ items: [] });
  

  const [rowCountState, setRowCountState] = useState(0);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const onFilterModelChange = (e) => {
    console.log(e)
    setFilterModel(e);
  }

  const onSortModelChange = (e) => {
    const firstObject = e[0];

    if (firstObject) {
      setSortModel(firstObject.field + ';' + String(firstObject.sort).toUpperCase())
    } else {
      setSortModel('createdAt;DESC')
    }
  }

  const fetchSelectData = async () => {
    try {
      const units = await UnitService.getUnits();
      const roles = await RoleService.getRoles();

      if (!units.isAxiosError) {
        const unitStr = units ? units.map(unit => unit.name) : [];
        setUnits(unitStr);
      }

      if (!roles.isAxiosError) {
        const roleStr = roles ? roles.map(role => { 
          return {
            label: ROLE_MAP[role.name],
            value: role.name
          }
        }) : [];
        setRoles(roleStr);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const createBody = () => {
    const items = filterModel.items;

    const body = {};

    items.forEach((e) => {
      body[e.field] = e.value;
    });

    return body;
  }

  const fetchUsers = async () => {
    try {
      const userList = await UserService.getUsersByPage(paginationModel, createBody(), sortModel);
      if (!userList.isAxiosError) {
        setUsers(userList.content);

        setRowCountState((prevRowCountState) =>
          userList?.totalElements !== undefined
            ? userList?.totalElements
            : prevRowCountState,
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {     
    fetchUsers();

    if (!units && !roles) {
      fetchSelectData();
    }
  }, [paginationModel, sortModel, filterModel]);

  const deleteUser = async (userName) => {
    try {
      const body = {
        userName: userName
      };
  
      await UserService.deleteUser(body);
      fetchUsers();
    } catch(error) {
      console.log(error);
    }
  } 
  
  const columns = [
    { 
      field: 'name', 
      headerName: 'Ad',
      width: 165, 
      sortable: true, 
      headerAlign: 'center',
      align: 'center',
      filterable: false,
    },
    { 
      field: 'surname', 
      headerName: 'Soyad', 
      width: 165, 
      sortable: true, 
      headerAlign: 'center',
      align: 'center',
      filterable: false
    },
    {
      field: 'userName',
      headerName: 'Kullanıcı Adı',
      sortable: true,
      width: 130,
      headerAlign: 'center',
      align: 'center',
      filterable: false
    },
    {
      field: 'organization',
      headerName: 'Birim',
      type: 'singleSelect',
      valueOptions: units ? units : [],
      sortable: true,
      width: 160,
      valueGetter: (params) => `${params.row.organization ? params.row.organization.name : ''}`,
      headerAlign: 'center',
      align: 'center',
      filterable: user && user.role === 'ROLE_ADMIN',
      filterOperators: orgFilterOperators()
    },
    {
      field: 'role',
      headerName: 'Rol',
      type: 'singleSelect',
      valueOptions: roles ? roles : [],
      sortable: true,
      width: 160,
      valueGetter: (params) => `${params.row.role ? params.row.role.name : ''}`,
      headerAlign: 'center',
      align: 'center',
      filterable: true,
      filterOperators: roleFilterOperators
    },
    {
      field: "options",
      headerName: "",
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      width: 190, 
      filterable: false,
      renderCell: (params) => {
        return <CustomizedMenus row={params.row} fetch={fetchUsers} handleOk={deleteUser}></CustomizedMenus>;
      }
    }
  ];

  const handleRefresh = async () =>  {
    await fetchUsers();
  }

  const lgUp = useResponsive('up', 'lg');

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ justifyContent: 'end' }}>
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }
  

  return (
    <PullToRefresh onRefresh={handleRefresh} isPullable={!lgUp}>
      <Container component="main" maxWidth="lg" sx={{backgroundColor: "#F9FAFB"}}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <PeopleIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Kullanıcılar
        </Typography>
        <DataGrid
          sx={{ minHeight: '400px', mt: 3, width: '85%' }}
          rows={users}
          columns={columns}
          rowSelection={false}
          slots={{ toolbar: CustomToolbar }}
          rowCount={rowCountState}
          paginationMode="server"
          filterMode="server"
          sortingMode="server"
          paginationModel={paginationModel}
          pageSizeOptions={[5, 10, 15]}
          onPaginationModelChange={setPaginationModel}
          filterModel={filterModel}
          onFilterModelChange={onFilterModelChange}
          onSortModelChange={onSortModelChange}
          localeText={
          {
            ...trTR.components.MuiDataGrid.defaultProps.localeText,
            ...{
              MuiTablePagination: {
                labelDisplayedRows: ({ from, to, count }) =>
                  `${from} - ${to} Toplamda ${count}`,
                labelRowsPerPage: 'Sayfa başına satır:'
              },
            }
          }}
        />
        
        </Box>
      </Container>
    </PullToRefresh>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, padding, ...other } = props;

  return (
    <DialogTitle 
      sx={{ m: 0, p: padding }} 
      {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export function CustomizedDialogs({ user, fetchUsers }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const updateComplete = () => {
    handleClose();
    fetchUsers();
  }

  return (
    <div>
      <MenuItem onClick={handleClickOpen} disableRipple>
        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
        Güncelle
      </MenuItem>
      <BootstrapDialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >

        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} padding={3}>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <UserUpdate userData={user} updateComplete={updateComplete}></UserUpdate>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export function PasswordUpdateDialog({ user, fetchUsers }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const updateComplete = () => {
    handleClose();
    fetchUsers();
  }

  return (
    <div>
      <MenuItem onClick={handleClickOpen} disableRipple>
        <Iconify icon={'carbon:password'} sx={{ mr: 2 }} />
        Şifre Değiştir
      </MenuItem>
      <BootstrapDialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} padding={3}>
        </BootstrapDialogTitle>
        <DialogContent dividers sx={{ backgroundColor: '#F9FAFB' }}>
          <UserPasswordChange updateWithUserName={true} updateUser={user} onUpdateComplete={updateComplete}></UserPasswordChange>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

function ConfirmationDialogRaw(props) {
  const { userName, handleOk } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    handleCancel();
    handleOk(userName);
  };

  return (
    <div>
      <MenuItem sx={{ color: 'error.main' }}  onClick={handleOpen} disableRipple>
        <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
        Sil
      </MenuItem>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={open}
      >
        <DialogTitle>Onay</DialogTitle>
        <DialogContent dividers>
          <Typography>Kullanıcı silinecektir. Onaylıyor musunuz?</Typography>
        </DialogContent>
        <DialogActions>
        <Button variant='outlined' color='error' autoFocus onClick={handleCancel}>
            İptal
          </Button>
          <Button variant='outlined' color='primary' onClick={handleConfirm}>Onayla</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export function CustomizedMenus({ row, fetch, handleOk }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <CustomizedDialogs user={row} fetchUsers={fetch}></CustomizedDialogs>
        <PasswordUpdateDialog user={row} fetchUsers={fetch}></PasswordUpdateDialog>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <ConfirmationDialogRaw userName={row.userName} handleOk={handleOk}></ConfirmationDialogRaw>
      </StyledMenu>
    </div>
  );
}