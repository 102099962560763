import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import NotificationService from './services/NotificationService';
import { FCM } from "@capacitor-community/fcm";
import {
  PushNotifications
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';

let messaging = {};

export const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
  });

export const webFirebaseInit = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyBmaPPhTteWVr381j8j9ncjb_lX07KBk64",
    authDomain: "cioistakipnotification-582ca.firebaseapp.com",
    projectId: "cioistakipnotification-582ca",
    storageBucket: "cioistakipnotification-582ca.appspot.com",
    messagingSenderId: "765610964118",
    appId: "1:765610964118:web:eca305ccad2ad5f786beb7"
  };
  
  const firebaseApp = initializeApp(firebaseConfig);
  messaging = getMessaging(firebaseApp);

  getToken(messaging, {vapidKey: 'BOA6jpEd7e1MBCYfLOynKQKf0jS-szI6dyasox69aTWmzxclHeHbWYvVxYAxVQ5nyzLjr02ppbOBv35Edm5iB7U'})
    .then(async (currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
        const body = {
          platform: 'WEB',
          token: currentToken
        }

        await NotificationService.createUserToken(body);
      } else {
        console.log('No registration token available. Request permission to generate one.');
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
}

export const mobileFirebaseInit = async (onMessageReceived) => {
  await PushNotifications.requestPermissions().then((permission) => {
    if (permission.receive == "granted") {
      // Register with Apple / Google to receive push via APNS/FCM
      if(Capacitor.getPlatform() == 'ios'){
        PushNotifications.register().then((res)=>{
          console.log('From Regisiter Promise', res)
        })
        PushNotifications.addListener('registration', (token)=>{            
          FCM.getToken()
            .then(async (r) => {
              const currentToken =  r.token;
              if (currentToken) {
                console.log('current token for client: ', currentToken);
                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
                const body = {
                  platform: Capacitor.getPlatform().toUpperCase(),
                  token: currentToken
                }

                await NotificationService.createUserToken(body);
              } else {
                console.log('No registration token available. Request permission to generate one.');
              }
            })
          .catch((err) => console.log(err));
        })
      } else{
        FCM.getToken()
            .then(async (r) => {
              const currentToken =  r.token;
              if (currentToken) {
                console.log('current token for client: ', currentToken);
                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
                const body = {
                  platform: Capacitor.getPlatform().toUpperCase(),
                  token: currentToken
                }

                await NotificationService.createUserToken(body);
              } else {
                console.log('No registration token available. Request permission to generate one.');
              }
            })
          .catch((err) => console.log(err));
      }
    } else {
      // No permission for push granted
      alert('No Permission for Notifications!')
    }
  });

  await PushNotifications.addListener('pushNotificationReceived',
    (notification) => {
      const data = notification.data;
      if (data) {
        onMessageReceived(data.snackbarMessage);
      }
    }
  );
}

