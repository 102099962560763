import { Capacitor } from '@capacitor/core';
import Cookie from './utils/cookie';

const webIp = 'https://www.cioistakip.com';

const mobileIp = {
    evistakip: 'https://www.evistakip.com',
    cioistakip: 'https://www.cioistakip.com'
}

export const getConfig = async () => {
    if (Capacitor.isNativePlatform()) {
        const organization = await Cookie.getName('organization')

        if (organization && organization !== '') {
            return mobileIp[organization];
        } else {
            await Cookie.logout();
            window.location.href = '/login';
            return;
        }
    }

    return webIp;
}