import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useState } from 'react';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import TaskService from '../../../services/TaskService';
import Alert from '@mui/material/Alert';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { tr } from "date-fns/locale";

export default function AssignmentUpdate({ task, fetchTasks, handleClose }) {

  const [formError, setFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [currentError, setCurrentError] = useState(null);
  const [errorDate, setErrorDate] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormError(false);

    const data = new FormData(event.currentTarget);

    let dueDate = '';
    if (data.get('dueDate') && !errorDate) {
      dueDate = data.get('dueDate').split(".").reverse().join("-");
    } else {
      let errorMessage = 'Lütfen tarih seçiniz';
      if (currentError === 'minDate') {
        errorMessage = 'Lütfen geçerli bir tarih seçiniz'
      }
      setErrorMessage(errorMessage);
      setFormError(true);
      return;
    }

    const body = {
      urgency: data.get('urgency'),
      subject: data.get('subject'),
      description: data.get('description'),
      dueDate: dueDate
    };

    try {
      await TaskService.updateTask(task.id, body);
      handleClose();
      fetchTasks();
    } catch (error) {
      console.log(error);
      setFormError(true);
    }
  };

  return (
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 3,
            marginBottom: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <TaskOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Görev Güncelleme
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  defaultValue={task.subject}
                  autoComplete="Konu"
                  name="subject"
                  required
                  fullWidth
                  id="subject"
                  label="Konu"
                  autoFocus
                  multiline
                  rows={3}
                  maxRows={3}
                  inputProps={{ maxLength: 255 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  defaultValue={task.description}
                  required
                  fullWidth
                  id="description"
                  label="Açıklama"
                  name="description"
                  autoComplete="Açıklama"
                  multiline
                  rows={3}
                  maxRows={3}
                  inputProps={{ maxLength: 255 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  defaultValue={task.urgency}
                  id="urgency"
                  name="urgency"
                  select
                  label="Aciliyet"
                  required
                  fullWidth
                >
                  <MenuItem value={"LOW"}>Düşük Aciliyet</MenuItem>
                  <MenuItem value={"MEDIUM"}>Orta Aciliyet</MenuItem>
                  <MenuItem value={"HIGH"}>Yüksek Aciliyet</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>    
              <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns}>
                <DatePicker
                  defaultValue={new Date(task.dueDate)}
                  minDate={new Date()}
                  sx={{ width: '100%' }}
                  label="Bitiş Tarihi"
                  onError={(reason, value) => {
                    if (reason) {
                      // reason is the error , which it will be displayed as
                      // default error message ,you can also  pass your own error
                      // message depending on the error
        
                      setCurrentError(reason);
                      setErrorDate(true);
                    } else {
                      setCurrentError(null);
                      setErrorDate(false);
                    }
                  }}
                  localeText={{
                    cancelButtonLabel: "İptal",
                    okButtonLabel: "Tamam"
                  }}
                  slotProps={{ textField: { required: true, id: 'dueDate', name: 'dueDate' }, toolbar: { hidden: true } }}/>
              </LocalizationProvider>
              </Grid>
            </Grid>
            { formError ? <Grid item xs={12} sx={{ mt: 3 }}>
                <Alert severity="error">{errorMessage ? errorMessage : 'Görev güncellenemedi, lütfen daha sonra tekrar deneyiniz.'}</Alert>
            </Grid> : <></>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, minHeight:'46px !important' }}
            >
              Güncelle
            </Button>
          </Box>
        </Box>
      </Container>
  );
}