// @mui
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Card, Divider, Typography, CardHeader, Popover, MenuItem, IconButton, Chip } from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { STATUS_MAP, URGENCY_MAP, STATUS_COLOR_MAP, URGENCY_COLOR_MAP, STATUS_ICON_MAP, URGENCY_ICON_MAP, formatDate, getDateColor } from '../../../locales/constants';
import Pagination from '@mui/material/Pagination';
import TaskService from '../../../services/TaskService';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import AssignmentUpdate from './AssignmentUpdate';
import { Avatar, Upload, theme }  from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import TaskDetails from './TaskDetails';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AlarmIcon from '@mui/icons-material/Alarm';
import UserService from '../../../services/UserService';
import UnitService from '../../../services/UnitService';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import useResponsive from '../../../hooks/useResponsive';
import heic2any from "heic2any";
import imageCompression from 'browser-image-compression';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { tr } from "date-fns/locale";
import Alert from '@mui/material/Alert';
// ----------------------------------------------------------------------

TaskList.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function TaskList({ subheader, tasks, fetchTasks, manager, admin, townManager, userName }) {
  const [taskFilter, setTaskFilter] = useState({});
  const [sort, setSort] = useState('createdAt;DESC');

  const handleChange = (event, value) => {
    fetchTasks(value - 1, taskFilter, sort);
  }

  const handleTaskFilter = (page, body) => {
    setTaskFilter(body);
    fetchTasks(page, body, sort);
  }
  
  const onSortChange = (event, page) => {
    const sortDirection = event.target.value;
    setSort(sortDirection);
    fetchTasks(page, taskFilter, sortDirection);
  }

  const smUp = useResponsive('up', 'sm');

  if (tasks && tasks.taskPage) {
    const {content, number, totalPages} = tasks.taskPage;
    if (content) {
      return (
        <Card>
          <CardHeader sx={{ pt: 1 }} title={<SelectLabels manager={manager} admin={admin} page={number} taskFilter={taskFilter} handleTaskFilter={handleTaskFilter} sort={sort} userName={userName} onSortChange={onSortChange}></SelectLabels>} subheader={subheader}></CardHeader>

          <Scrollbar sx={{ pt: 1 }}>
            <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
              {content.map((task, i, {length}) => (
                <>
                  <TaskItem key={task.id} task={task} fetchTasks={fetchTasks} page={number} manager={manager} admin={admin} townManager={townManager} userName={userName} taskFilter={taskFilter}/>
                  {length - 1 === i ? null : <Divider />}
                </>
              ))}
            </Stack>
          </Scrollbar>
    
          <Divider />
    
          <Box sx={{ p: 2, justifyContent:"center", display:'flex' }}>
            <Pagination 
              size={smUp ? 'medium' : 'small'}
              page={number + 1}
              count={totalPages}
              onChange={handleChange}
            />
          </Box>
        </Card>
      );
    }
  }
}

// ----------------------------------------------------------------------

TaskItem.propTypes = {
  task: PropTypes.shape({
    subject: PropTypes.string,
    description: PropTypes.string,
    attachments: PropTypes.array,
    createdAt: PropTypes.instanceOf(Date),
  }),
};

function TaskItem({ task, fetchTasks, page, manager, admin, townManager, userName, taskFilter}) {
  const { id, subject, description, status,  urgency, attachments, assigneeFullName, assigneeUserName, organizationId, organizationName, statusMessage, returnReason, confirmationMessage, dueDate, createdAt } = task;
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [photoOpen, setPhotoOpen] = useState(false);
  const [taskDialogOpen, setTaskDialogOpen] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleMarkInProgress = async (statusMessage) => {
    handleCloseMenu();
    const body = {
      status: 'IN_PROGRESS',
      statusMessage: statusMessage
    };
    
    await TaskService.updateTaskStatus(id, body)
    fetchTasks(page, taskFilter);
  };

  const handleMarkSendToConfirm = async (statusMessage) => {
    handleCloseMenu();
    const body = {
      status: 'WAITING_APPROVE',
      statusMessage: statusMessage,
    };

    await TaskService.updateTaskStatus(id, body)
    fetchTasks(page, taskFilter);
  };

  const handleMarkComplete = async (statusMessage) => {
    handleCloseMenu();
    const body = {
      status: 'DONE',
      confirmationMessage: statusMessage,
    };

    await TaskService.updateTaskStatus(id, body)
    fetchTasks(page, taskFilter);
  };

  const handleMarkDenied = async (statusMessage) => {
    handleCloseMenu();
    const body = {
      status: 'DENIED',
      confirmationMessage: statusMessage,
    };

    await TaskService.updateTaskStatus(id, body)
    fetchTasks(page, taskFilter);
  };

  const handleMarkReturn = async (statusMessage) => {
    handleCloseMenu();
    const body = {
      status: 'RETURNED',
      returnReason: statusMessage,
    };

    await TaskService.updateTaskStatus(id, body)
    fetchTasks(page, taskFilter);
  }

  const handleMarkNotApproved = async (statusMessage) => {
    handleCloseMenu();
    const body = {
      status: 'NOT_APPROVED',
      returnReason: statusMessage,
    };

    await TaskService.updateTaskStatus(id, body)
    fetchTasks(page, taskFilter);
  }

  const handleMarkSentBack = async (statusMessage) => {
    handleCloseMenu();
    const body = {
      status: 'SENT_BACK',
      returnReason: statusMessage,
    };

    await TaskService.updateTaskStatus(id, body)
    fetchTasks(page, taskFilter);
  }
  
  const handleAssign = async (body) => {
    handleCloseMenu();

    try {
      await TaskService.assignTask(id, body)
      fetchTasks(page, taskFilter);
    } catch (error) {
      console.log(error);
    }
  }

  const handleUpdateRequested = async () => {
    handleCloseMenu();
    
    fetchTasks(page, taskFilter);
  }

  const handleDelete = async () => {
    handleCloseMenu();

    try {
      await TaskService.deleteTask(id)
      fetchTasks(page, taskFilter);
    } catch (error) {
      console.log(error);
    }
  }

  const src = status === "DONE" ? "/assets/images/covers/completed-task.png" : "/assets/images/covers/task.png";

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleEdit = async () => {
    setDialogOpen(true);
  };

  const handlePhotoClose = () => {
    setPhotoOpen(false);
  };

  const handlePhoto = () => {
    setPhotoOpen(true);
  };

  function BootstrapDialogTitle(props) {
    const { children, onClose, padding, ...other } = props;
  
    return (
      <DialogTitle 
        sx={{ m: 0, p: padding }} 
        {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  const createImages = (attachmentList) => {
    return attachmentList.map((attachment) => {
      const image = "data:image/png;base64," + attachment.image;
      return {
        uid: attachment.id,
        name: 'image.png',
        status: 'done',
        thumbUrl: image,
        preview: image
      }
    })
  }

  const [fileList, setFileList] = useState(createImages(attachments));
  
  const [previewImage, setPreviewImage] = useState('');

  const changeImageFormat = async (file) => {

    if(file.type) {
      return await resizeFile(file);
    }

    return await heic2any({
        blob: file,
        toType: "image/jpg",
    })
        .then(async (resultBlob) => {
            let file = new File([resultBlob], "image.jpg", {type:"image/jpeg", lastModified:new Date().getTime()});
            return await resizeFile(file);
        })
        .catch(function (x) {
            console.log(x.code);
            console.log(x.message);
        });

  }

  const resizeFile = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      fileType: 'image/jpeg'
    };

    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.log(error);
    }
  };

  const props = {
    name: 'file',
    async customRequest(options) {
      const { file } = options;
      setUploadLoading(true);

      try {
        const fmData = new FormData();
        fmData.append("file", await changeImageFormat(file));
        const updatedTask = await TaskService.addAttachment(task.id, fmData);
        if (!updatedTask.isAxiosError) {
          setFileList(createImages(updatedTask.attachments))
          setUploadLoading(false);
        }
      } catch(error) {
        console.log(error);
        setUploadLoading(false);
      }
    },
    onChange(info) {
      const status = info.file.status;
      if (status !== 'uploading') {
      }
      if (status === 'done') {
        // show success message
      } else if (status === 'error') {
        // show error message
      }
    },
    async onPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
  
      setPreviewImage(file.url || file.preview);
    },
    async onRemove(file) {
      try {
        const updatedTask = await TaskService.deleteAttachment(task.id, file.uid);
        if (!updatedTask.isAxiosError) {
          const images = createImages(updatedTask.attachments);
          setFileList(images);

          if (images.length === 0) {
            setPreviewImage('');
          }
        }
      } catch(error) {
        console.log(error);
      }
    }
  };

  const uploadButton = () => {
    if (townManager) {
      return null;
    }

    return (
      <div>
        {uploadLoading ? <CircularProgress sx={{ color: 'grey'}} /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Fotoğraf Ekle</div>
      </div>
    );
  }

  const mdUp = useResponsive('up', 'md');
  const smUp = useResponsive('up', 'sm');

  const refreshUpdate = () => {
    fetchTasks(page, taskFilter);
  }
  
  return (
    <Stack direction="row" alignItems="center" spacing={2}>

      <BootstrapDialog
        fullWidth={true}
        maxWidth={"md"}
        onClose={() => setTaskDialogOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={taskDialogOpen}
        >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setTaskDialogOpen(false)} padding={3}>
        </BootstrapDialogTitle>
        <DialogContent dividers >
          <TaskDetails task={task} manager={manager} admin={admin}></TaskDetails>
        </DialogContent>
      </BootstrapDialog>


      <Grid container spacing={0}>
        {smUp ? (
          <Grid lg={0.7} md={0.8} sm={1} sx={{ alignSelf: 'end', mb: { md:'3px', sm: '5px', xs: '7px'} }}>
            <Box component="img" alt={subject} src={src} sx={{ width: { lg: 46, md: 44, sm: 44, xs: 36 }, flexShrink: 0 }} onClick={() => setTaskDialogOpen(true)}/>
          </Grid>) : (<></>)}
        <Grid lg={7.6} md={7} sm={7.6} xs={7}>
          <Box  onClick={() => setTaskDialogOpen(true)}>

            <Box>
              {mdUp && (admin || manager || townManager) ? (<Chip avatar={<AccountCircleIcon></AccountCircleIcon>} label={assigneeFullName || 'Atanmamış'} variant="outlined" sx={{ marginLeft: { md: '-5px'}, marginBottom: '10px', borderColor: '#212B36' }} />) : (<></>)}
              {mdUp && (admin || townManager) ? (<Chip avatar={<ApartmentIcon></ApartmentIcon>} label={organizationName || 'Atanmamış'} variant="outlined" sx={{ marginLeft: { md: '10px' }, marginBottom: '10px', borderColor: '#212B36' }} />) : (<></>)}
              <Chip avatar={<AlarmIcon style={{ color: '#fff', width: mdUp ? '24px' : '18px' }}></AlarmIcon>} label={<span style={{ fontSize: mdUp ? '0.8125rem' : '0.7rem' }}>{formatDate(dueDate)}</span>} sx={{ marginLeft: mdUp && (admin || manager || townManager) ? '10px' : '-5px', marginBottom: '10px', color: '#fff', bgcolor: (theme) => getDateColor(dueDate, theme) }} />
            </Box>
            <Box sx={{ pt: '5px' }}>
              <Typography color="inherit" variant="subtitle2" underline="hover" sx={{ fontSize: '16px' }} noWrap>
                {subject}
              </Typography>
              
              <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '15px', paddingTop: '3px' }} noWrap>
                {description}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid lg={3.7} md={4.2} sm={3.4} xs={4.8} sx={{ alignSelf: 'end' }}>
          <Grid container sx={{ textAlign: 'right', justifyContent: 'right',  pb: 2, pr: { md: '25px', sm: '20px', xs: '10px' }}}>
            <Grid md={12}>
              <Typography variant="caption" sx={{ flexShrink: 0, color: 'text.secondary' }}>
                {fToNow(createdAt)}
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="caption" sx={{ pr: { md: '15px', sm: '10px', xs: '0px' }, flexShrink: 0, color: 'text.secondary', alignItems: 'center', float: 'right' }}>
          <Grid container>
            <Grid md={11} sx={{ display: { md: 'inline-flex' }, justifyContent: 'right', alignSelf: 'center' }}>
              <Chip 
                avatar={<Avatar src={URGENCY_ICON_MAP[urgency]}></Avatar>} 
                label={mdUp ? URGENCY_MAP[urgency] : ''} variant="outlined" 
                sx={{ marginRight: '10px', color: (theme) => theme.palette[URGENCY_COLOR_MAP[urgency]].darker, borderColor: (theme) => theme.palette[URGENCY_COLOR_MAP[urgency]].dark, '& .MuiChip-label': { paddingLeft: { xs: 0, sm: 0, md: 1.5, lg: 1.5 } } }}/>
              <Chip 
                avatar={<Avatar src={STATUS_ICON_MAP[status]}></Avatar>} 
                label={mdUp ? STATUS_MAP[status] : ''} 
                sx={{ marginRight: '10px', borderWidth: '2px', color: (theme) => theme.palette[STATUS_COLOR_MAP[status]].lighter, bgcolor: (theme) => theme.palette[STATUS_COLOR_MAP[status]].darker, '& .MuiChip-label': { paddingLeft: { xs: 0, sm: 0, md: 1.5, lg: 1.5 } }}} />
            </Grid>
            <Grid md={1}>
              <IconButton size="large" color="inherit" sx={{ opacity: 0.52, pl: 0, pr: 0 }} onClick={handleOpenMenu}>
                <Iconify icon={'eva:more-vertical-fill'} />
              </IconButton>
            </Grid>
          </Grid>

          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                p: 1,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
          >

            {townManager ? <></> : (
              "ASSIGNED" === status ? (
                  <ConfirmationDialogRaw handLeOk={handleMarkInProgress} message={statusMessage} buttonText='Çalışılıyor Olarak İşaretle' buttonIcon='carbon:in-progress'></ConfirmationDialogRaw>
              ) : ("IN_PROGRESS" === status) && (manager || admin) ? (
                  <ConfirmationDialogRaw handLeOk={handleMarkComplete} message={statusMessage} buttonText='Tamamlandı Olarak İşaretle' buttonIcon='eva:checkmark-circle-2-fill'></ConfirmationDialogRaw>
              ) : ("IN_PROGRESS" === status) && (!manager && !admin) ? (
                  <ConfirmationDialogRaw handLeOk={handleMarkSendToConfirm} message={statusMessage} buttonText='Onaya Gönder' buttonIcon='eva:checkmark-circle-2-fill'></ConfirmationDialogRaw>
              ) : ("DONE" === status) && (manager || admin) ?
                  <ConfirmationDialogRaw handLeOk={handleMarkSentBack} message={returnReason} buttonText='Geri Gönder' buttonIcon='eva:arrow-circle-left-fill'></ConfirmationDialogRaw>
              : ("SENT_BACK" === status) ?
                  <ConfirmationDialogRaw handLeOk={handleMarkInProgress} message={statusMessage} buttonText='Çalışılıyor Olarak İşaretle' buttonIcon='carbon:in-progress'></ConfirmationDialogRaw>
              : ("DENIED" === status) ?
                  <ConfirmationDialogRaw handLeOk={handleMarkInProgress} message={statusMessage} buttonText='Çalışılıyor Olarak İşaretle' buttonIcon='carbon:in-progress'></ConfirmationDialogRaw>
              : ("RETURNED" === status) ?
                  <ConfirmationDialogRaw handLeOk={handleMarkInProgress} message={statusMessage} buttonText='Çalışılıyor Olarak İşaretle' buttonIcon='carbon:in-progress'></ConfirmationDialogRaw>
              : ("REQUESTED" === status) ? 
                <div>
                  <RequestUpdateDialog task={task} handLeOk={handleUpdateRequested} type='approve' buttonText='Talebi Onayla' buttonIcon='eva:checkmark-circle-2-fill'></RequestUpdateDialog>
                  <RequestUpdateDialog task={task} handLeOk={handleUpdateRequested} type='reject' buttonText='Talebi Reddet' buttonIcon='mdi:cancel-circle-outline'></RequestUpdateDialog>
                </div>
              : <></>
            )}

            {townManager && "NOT_APPROVED" === status ? (
              <RequestUpdateDialog task={task} handLeOk={handleUpdateRequested} type='update' buttonText='Talebi Tekrarla' buttonIcon='eva:edit-fill'></RequestUpdateDialog>
            ) : (<></>)}

            {"ASSIGNED" === status && manager && userName === task.assigneeUserName ? (
              <ConfirmationDialogRaw handLeOk={handleMarkNotApproved} message={returnReason} buttonText='Uygun Görülmedi' buttonIcon='mdi:note-off'></ConfirmationDialogRaw>
            ) : <></>}

            {("DONE" !== status) && (!manager && !admin && !townManager) ? (
              <ConfirmationDialogRaw handLeOk={handleMarkReturn} message={returnReason} buttonText='İade Et' buttonIcon='eva:arrow-circle-left-fill'></ConfirmationDialogRaw>
            ) : (<></>)}

            {("WAITING_APPROVE" === status) && (manager || admin) ? (
              <ConfirmationDialogRaw handLeOk={handleMarkComplete} message={confirmationMessage} buttonText='Onayla' buttonIcon='eva:checkmark-circle-2-fill'></ConfirmationDialogRaw>
            ) : (<></>)}

            {("WAITING_APPROVE" === status) && (manager || admin) ? (
              <ConfirmationDialogRaw handLeOk={handleMarkDenied} message={confirmationMessage} buttonText='Reddet' buttonIcon='mdi:cancel-circle-outline'></ConfirmationDialogRaw>
            ) : (<></>)}

            {("REQUESTED" !== status) && (admin || manager) ? (
              <AssignmentConfirmationDialogRaw handLeOk={handleAssign} admin={admin} manager={manager} type={'unit'} organizationId={organizationId} buttonText='Farklı Birime Ata' buttonIcon='material-symbols:apartment'></AssignmentConfirmationDialogRaw>
            ) : (<></>)}

            {("REQUESTED" !== status) && manager ? (
              <AssignmentConfirmationDialogRaw handLeOk={handleAssign} admin={admin} manager={manager} type={'user'} assigneeUserName={assigneeUserName} buttonText='Farklı Kullanıcıya Ata' buttonIcon='material-symbols:assignment-ind'></AssignmentConfirmationDialogRaw>
            ) : <></>}
            

            {(admin || manager) ? (
              <MenuItem onClick={handleEdit}>
              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                Güncelle
              </MenuItem>
            ) : <></>}

            {(admin) ? (
              <DeleteDialogRaw handleOk={handleDelete}></DeleteDialogRaw>
            ) : <></>}

            <Divider sx={{ borderStyle: 'dashed' }} />

            <MenuItem onClick={handlePhoto}>
              <Iconify icon={'material-symbols:photo'} sx={{ mr: 2 }} />
              Fotoğraflar
            </MenuItem>

            <BootstrapDialog
              fullWidth={true}
              maxWidth={"md"}
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={dialogOpen}
              >
              <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} padding={3}>
              </BootstrapDialogTitle>
              <DialogContent dividers >
                <AssignmentUpdate task={task} fetchTasks={refreshUpdate} handleClose={handleClose}></AssignmentUpdate>
              </DialogContent>
            </BootstrapDialog>

            <BootstrapDialog
              fullWidth={true}
              maxWidth={"md"}
              onClose={handlePhotoClose}
              aria-labelledby="customized-dialog-title"
              open={photoOpen}
              >
              <BootstrapDialogTitle id="customized-dialog-title" onClose={handlePhotoClose} padding={2}>
                Fotoğraflar
              </BootstrapDialogTitle>
              <DialogContent dividers >
                <Box sx={{ maxHeight: 600, flexGrow: 1, display: 'flex', placeContent: 'center', mb: 3 }}>
                  {previewImage ? 
                  (<img
                    alt="example"
                    width={600}
                    src={previewImage}
                  />) : (<></>)}
                </Box>
                {previewImage ?  (<Divider sx={{ borderStyle: 'solid', mb: 3 }} />) : (<></>)}
                <Box sx={{ textAlign: 'center' }}>
                  {townManager && fileList.length === 0 ? <Typography>Kaydedilmiş fotoğraf bulunamadı</Typography> : <></>}
                  <Upload
                    {...props}
                    disableRemove={true}
                    listType="picture-card"
                    fileList={fileList}
                    className="image-upload-grid"
                    showUploadList={{
                      showRemoveIcon: !townManager,
                    }}
                  >
                    {fileList.length >= 4 ? null : uploadButton()}
                  </Upload>
                </Box>
              </DialogContent>
            </BootstrapDialog>
          </Popover>
        </Typography>
      </Grid>
    </Grid>
    </Stack>
  );
}

export function SelectLabels({ manager, admin, taskFilter, handleTaskFilter, userName, sort, onSortChange }) {
  const [users, setUsers] = useState([]);
  const [units, setUnits] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'selectPopover' : undefined;  

  const fetchUsers = async () => {
    try {
      const userList = await UserService.getUsers();

      if (!userList.isAxiosError) {
        const sortedList = userList.sort((a,b)=>{
          if (a.userName === userName) {
            return -1;
          }
    
          if (b.userName === userName) {
            return 1;
          }
    
          return 0;
        });
    
        setUsers(sortedList);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const fetchUnitUsers = async (selectedUnit) => {
    if (selectedUnit) {
      const userList = await UnitService.getUnitUsers(selectedUnit);
      if (!userList.isAxiosError) {
        const sortedList = userList.sort((a,b)=>{
          if (a.userName === userName) {
            return -1;
          }
    
          if (b.userName === userName) {
            return 1;
          }
    
          return 0;
        });
    
        setUsers(sortedList);
      }
    } else {
      setUsers([]);
    }
  }

  const fetchUnits= async () => {
    try {
      const unitList = await UnitService.getUnits();

      if (!unitList.isAxiosError) {
        setUnits(unitList);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (admin) {
      fetchUnits();
    } else if (manager) {
      fetchUsers();
    }
  }, [manager, admin]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    handleClose();

    const data = new FormData(event.currentTarget);

    const body = {};
    const urgency = data.get('urgency');
    const status = data.get('status');
    const unit = data.get('unit');
    const user = data.get('user');

    if (urgency) {
      body.urgency = urgency;
    }

    if (status) {
      body.status = status;
    }

    if (admin && unit) {
      body.organizationId = unit;
    }

    if ((admin || manager) && user) {
      body.assigneeUserId = user;
    }

    handleTaskFilter(0, body);
  };

  const onUnitChange = (event) => {
    fetchUnitUsers(event.target.value);
  }

  const mdUp = useResponsive('up', 'lg');

  return (
    <Grid container spacing={3} sx={{ pt: 1 }}>
      <Grid item xs={1} sx={{ alignSelf: 'center' }}>
        {'Görevler'}
      </Grid>
      <Grid item xs={11} sx={{ textAlign: 'right' }}>
      {!mdUp ? (
        <>
        <FormControl sx={{ m: 1.3 }} size="small">
          <Button
            onClick={handleClick}
            variant="outlined"
            sx={{ pt: { xs: '7px', sm: '7px', md: '7px', lg: '5px' }, pb: { xs: '7px', sm: '7px', md: '7px', lg: '5px' }, borderColor: '#2065D1' }}>
            <FilterListIcon fontSize="small"/> {mdUp ? <span style={{ paddingLeft: '5px' }}>Filtrele</span> : <></>}
          </Button>
        </FormControl>
        <FormControl size="small" sx={{ m: 1, maxWidth: { lg: 140, md: 140, sm: 140, xs: 80 } }}>
          <InputLabel id="sort-label">Sıralama</InputLabel>
          <Select
            labelId="sort-label"
            id="sort"
            name="sort"
            label="Sıralama"
            value={sort}
            onChange={(event) => onSortChange(event, 0)}
          >
            <MenuItem value={"createdAt;DESC"}>Oluşturma Tarihi Azalan</MenuItem>
            <MenuItem value={"createdAt;ASC"}>Oluşturma Tarihi Artan</MenuItem>
            <MenuItem value={"dueDate;DESC"}>Kalan Süre Azalan</MenuItem>
            <MenuItem value={"dueDate;ASC"}>Kalan Süre Artan</MenuItem>
          </Select>
        </FormControl>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container sx={{ pt: 1, width: 'min-content' }}>
            {admin ? (
              <Grid item xs={12}>
                <FormControl sx={{ m: 1, minWidth: 160, maxWidth: 160 }} size="small">
                  <InputLabel id="unit-label">Birim</InputLabel>
                  <Select
                    labelId="unit-label"
                    id="unit"
                    name="unit"
                    label="Birim"
                    defaultValue={taskFilter ? taskFilter.organizationId : ''}
                    onChange={onUnitChange}
                  >
                    <MenuItem value="">
                      <em>Hepsi</em>
                    </MenuItem>
                  {units.map(unit => <MenuItem value={unit.id}>{unit.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>) : <></>}
            {(admin || manager) && users.length > 0 ? (
            <Grid item xs={12}>
            <FormControl sx={{ m: 1, minWidth: 160, maxWidth: 160 }} size="small">
              <InputLabel id="user-label">Kullanıcı</InputLabel>
              <Select
                labelId="user-label"
                id="user"
                name="user"
                label="Kullanıcı"
                defaultValue={taskFilter ? taskFilter.assigneeUserId : ''}
              >
                <MenuItem value="">
                  <em>Hepsi</em>
                </MenuItem>
                {users.map((user) => (<MenuItem value={user.id}>{userName === user.userName ? 'Kendim' : user.name + " " + user.surname}</MenuItem>))}
              </Select>
            </FormControl>
            </Grid>) : <></>}
            <Grid item xs={12}>
            <FormControl sx={{ m: 1, minWidth: 160, maxWidth: 160 }} size="small">
              <InputLabel id="urgency-label">Aciliyet</InputLabel>
              <Select
                labelId="urgency-label"
                id="urgency"
                name="urgency"
                label="Aciliyet"
                defaultValue={taskFilter.urgency ? taskFilter.urgency : ''}
              >
                <MenuItem value="">
                  <em>Hepsi</em>
                </MenuItem>
                <MenuItem value={"LOW"}>Düşük Aciliyet</MenuItem>
                <MenuItem value={"MEDIUM"}>Orta Aciliyet</MenuItem>
                <MenuItem value={"HIGH"}>Yüksek Aciliyet</MenuItem>
              </Select>
            </FormControl>
            </Grid>
            <Grid item xs={12}>
            <FormControl sx={{ m: 1, minWidth: 160, maxWidth: 160 }} size="small">
              <InputLabel id="urgency-label">Durum</InputLabel>
              <Select
                labelId="urgency-label"
                id="status"
                name="status"
                label="Durum"
                defaultValue={taskFilter.status ? taskFilter.status : ''}
              >
                <MenuItem value="">
                  <em>Hepsi</em>
                </MenuItem>
                <MenuItem value={"ASSIGNED"}>Atandı</MenuItem>
                <MenuItem value={"IN_PROGRESS"}>Çalışılıyor</MenuItem>
                {manager || admin ? <MenuItem value={"RETURNED"}>İade Edildi</MenuItem> : <></>}
                <MenuItem value={"SENT_BACK"}>Geri Gönderildi</MenuItem>
                <MenuItem value={"WAITING_APPROVE"}>Onay Bekliyor</MenuItem>
                <MenuItem value={"DENIED"}>Reddedildi</MenuItem>
                <MenuItem value={"NOT_APPROVED"}>Uygun Görülmedi</MenuItem>
                <MenuItem value={"DONE"}>Tamamlandı</MenuItem>
              </Select>
            </FormControl>
            </Grid>
            <Grid item xs={12}>
            <FormControl sx={{ m: 1.3, minWidth: 160, maxWidth: 160 }} size="small">
              <Button
                type="submit"
                variant="outlined"
                fullWidth
                sx={{ pt: { xs: '7px', sm: '7px', md: '7px', lg: '5px' }, pb: { xs: '7px', sm: '7px', md: '7px', lg: '5px' }, borderColor: '#2065D1' }}>
                <FilterListIcon fontSize="small"/> <span style={{ paddingLeft: '5px' }}>Uygula</span>
              </Button>
            </FormControl>
            </Grid>
            </Grid>
          </Box>
        </Popover>
        </>
      ) : (
        <Box component="form" onSubmit={handleSubmit}>
          {admin ? (
            <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 120 }} size="small">
              <InputLabel id="unit-label">Birim</InputLabel>
              <Select
                labelId="unit-label"
                id="unit"
                name="unit"
                label="Birim"
                defaultValue={taskFilter ? taskFilter.organizationId : ''}
                onChange={onUnitChange}
              >
                <MenuItem value="">
                  <em>Hepsi</em>
                </MenuItem>
              {units.map(unit => <MenuItem value={unit.id}>{unit.name}</MenuItem>)}
              </Select>
            </FormControl>) : <></>}
          {(admin || manager) && users.length > 0 ? (
          <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 120 }} size="small">
            <InputLabel id="user-label">Kullanıcı</InputLabel>
            <Select
              labelId="user-label"
              id="user"
              name="user"
              label="Kullanıcı"
              defaultValue={taskFilter ? taskFilter.assigneeUserId : ''}
              >
              <MenuItem value="">
                <em>Hepsi</em>
              </MenuItem>
              {users.map((user) => (<MenuItem value={user.id}>{userName === user.userName ? 'Kendim' : user.name + " " + user.surname}</MenuItem>))}
            </Select>
          </FormControl>) : <></>}
          <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 120 }} size="small">
            <InputLabel id="urgency-label">Aciliyet</InputLabel>
            <Select
              labelId="urgency-label"
              id="urgency"
              name="urgency"
              label="Aciliyet"
              defaultValue={taskFilter.urgency ? taskFilter.urgency : ''}
            >
              <MenuItem value="">
                <em>Hepsi</em>
              </MenuItem>
              <MenuItem value={"LOW"}>Düşük Aciliyet</MenuItem>
              <MenuItem value={"MEDIUM"}>Orta Aciliyet</MenuItem>
              <MenuItem value={"HIGH"}>Yüksek Aciliyet</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 120 }} size="small">
            <InputLabel id="urgency-label">Durum</InputLabel>
            <Select
              labelId="urgency-label"
              id="status"
              name="status"
              label="Durum"
              defaultValue={taskFilter.status ? taskFilter.status : ''}
            >
              <MenuItem value="">
                <em>Hepsi</em>
              </MenuItem>
              <MenuItem value={"ASSIGNED"}>Atandı</MenuItem>
              <MenuItem value={"REQUESTED"}>Talep Edildi</MenuItem>
              <MenuItem value={"IN_PROGRESS"}>Çalışılıyor</MenuItem>
              {manager || admin ? <MenuItem value={"RETURNED"}>İade Edildi</MenuItem> : <></>}
              <MenuItem value={"SENT_BACK"}>Geri Gönderildi</MenuItem>
              <MenuItem value={"WAITING_APPROVE"}>Onay Bekliyor</MenuItem>
              <MenuItem value={"DENIED"}>Reddedildi</MenuItem>
              <MenuItem value={"NOT_APPROVED"}>Uygun Görülmedi</MenuItem>
              <MenuItem value={"DONE"}>Tamamlandı</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1.3, minWidth: 100, maxWidth: 100 }} size="small">
            <Button
              type="submit"
              variant="outlined"
              fullWidth
              sx={{ pt: { xs: '7px', sm: '7px', md: '7px', lg: '5px' }, pb: { xs: '7px', sm: '7px', md: '7px', lg: '5px' }, borderColor: '#2065D1' }}>
              <FilterListIcon fontSize="small"/> <span style={{ paddingLeft: '5px' }}>Filtrele</span>
            </Button>
          </FormControl>
          <FormControl size="small" sx={{ m: 1, maxWidth: { lg: 140, md: 70, sm: 70, xs: 70 } }}>
            <InputLabel id="sort-label">Sıralama</InputLabel>
            <Select
              labelId="sort-label"
              id="sort"
              name="sort"
              label="Sıralama"
              value={sort}
              onChange={(event) => onSortChange(event, 0)}
            >
              <MenuItem value={"createdAt;DESC"}>Oluşturma Tarihi Azalan</MenuItem>
              <MenuItem value={"createdAt;ASC"}>Oluşturma Tarihi Artan</MenuItem>
              <MenuItem value={"dueDate;DESC"}>Kalan Süre Azalan</MenuItem>
              <MenuItem value={"dueDate;ASC"}>Kalan Süre Artan</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}
      </Grid>
    </Grid>
  );
}

function ConfirmationDialogRaw(props) {
  const { handLeOk, message, buttonText, buttonIcon } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleConfirm = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);


    handleCancel();
    handLeOk(data.get('message'));
  };

  return (
    <div>
      <MenuItem onClick={handleOpen}>
      <Iconify icon={buttonIcon} sx={{ mr: 2 }} />
        {buttonText}
      </MenuItem>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={open}
        onClose={handleCancel}
      >
        <DialogTitle>{buttonText}</DialogTitle>
        <Box component="form" onSubmit={handleConfirm} sx={{ mt: 0}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DialogContent dividers>
                <TextField
                  autoComplete="Konu"
                  name="message"
                  fullWidth
                  id="message"
                  label="Açıklama"
                  autoFocus
                  multiline
                  defaultValue={message}
                  rows={3}
                  maxRows={3}
                  inputProps={{ maxLength: 255 }}
                />
              </DialogContent>
            </Grid>
            <Grid item xs={12}>
            <DialogActions>
              <Button variant='outlined' color='error' autoFocus onClick={handleCancel}>
                İptal
              </Button>
              <Button variant='outlined' color='primary' type="submit">Onayla</Button>
            </DialogActions>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </div>
  );
}

function DeleteDialogRaw(props) {
  const { handleOk } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleConfirm = (event) => {
    event.preventDefault();

    handleCancel();
    handleOk();
  };

  return (
    <div>
      <MenuItem sx={{ color: 'error.main' }} onClick={handleOpen}>
      <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
        Sil
      </MenuItem>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={open}
        onClose={handleCancel}
      >
        <DialogTitle>Silme Onayı</DialogTitle>
        <Box component="form" onSubmit={handleConfirm} sx={{ mt: 0}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DialogContent dividers>
                Görev silinecektir onaylıyor musunuz?
              </DialogContent>
            </Grid>
            <Grid item xs={12}>
            <DialogActions>
              <Button variant='outlined' color='error' autoFocus onClick={handleCancel}>
                İptal
              </Button>
              <Button variant='outlined' color='primary' type="submit">Onayla</Button>
            </DialogActions>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </div>
  );
}

function AssignmentConfirmationDialogRaw(props) {
  const { handLeOk, admin, manager, type, organizationId, assigneeUserName, buttonText, buttonIcon } = props;
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [units, setUnits] = useState([]);

  const fetchUsers = async () => {
    try {
      const userList = await UserService.getUsers();
      if (!userList.isAxiosError) {
        setUsers(userList);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const fetchUnits= async () => {
    try {
      const unitList = await UnitService.getUnits();

      if (!unitList.isAxiosError) {
        setUnits(unitList);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (admin || manager) {
      if (type === 'unit') {
        fetchUnits();
      } else if (type === 'user') {
        fetchUsers();
      }
    }
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleConfirm = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const body = {};

    if (type === 'unit') {
      body.organizationId = data.get('unit');
    } else if (type === 'user') {
      body.assigneeUserName = data.get('user');
    }

    handleCancel();
    handLeOk(body);
  };

  return (
    <div>
      <MenuItem onClick={handleOpen}>
      <Iconify icon={buttonIcon} sx={{ mr: 2 }} />
        {buttonText}
      </MenuItem>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={open}
        onClose={handleCancel}
      >
        <DialogTitle>{buttonText}</DialogTitle>
        <Box component="form" onSubmit={handleConfirm} sx={{ mt: 0}}>
          <Grid container spacing={2}>
            {type === 'unit' ? (
              <Grid item xs={12}>
              <DialogContent dividers>
                <TextField
                  id="unit"
                  name="unit"
                  select
                  label="Birim"
                  required
                  fullWidth
                  defaultValue={organizationId}
                >
                  {units.map(unit => <MenuItem value={unit.id}>{unit.name}</MenuItem>)}
                </TextField>
              </DialogContent>
            </Grid>) : (<></>)}
            {type === 'user' ? (
              <Grid item xs={12}>
              <DialogContent dividers>
                <TextField
                  id="user"
                  name="user"
                  select
                  label="Kullanıcı"
                  required
                  fullWidth
                  defaultValue={assigneeUserName}
                >
                  {users.map(user => <MenuItem value={user.userName}>{user.name + ' ' + user.surname}</MenuItem>)}
                </TextField>
              </DialogContent>
            </Grid>) : (<></>)}
          <Grid item xs={12}>
            <DialogActions>
              <Button variant='outlined' color='error' autoFocus onClick={handleCancel}>
                İptal
              </Button>
              <Button variant='outlined' color='primary' type="submit">Onayla</Button>
            </DialogActions>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </div>
  );
}

function RequestUpdateDialog(props) {
  const { task, handLeOk, type, buttonText, buttonIcon } = props;
  const [open, setOpen] = useState(false);
  const [units, setUnits] = useState([]);

  const [currentError, setCurrentError] = useState(null);
  const [errorDate, setErrorDate] = useState(false);

  const [formError, setFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const fetchUnits= async () => {
    try {
      const unitList = await UnitService.getUnits();
      if (!unitList.isAxiosError) {
        setUnits(unitList);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchUnits();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setFormError(false);
    setOpen(false);
  };

  const handleConfirm = async (event) => {
    event.preventDefault();

    setFormError(false);

    const data = new FormData(event.currentTarget);

    const body = {};

    if (type === 'update') {
      body.status = 'REQUESTED';
      body.subject = data.get('subject');
      body.description = data.get('description');
      body.urgency = data.get('urgency');
    } else if (type === 'approve') {
      let dueDate = '';

      if (data.get('dueDate') && !errorDate) {
        dueDate = data.get('dueDate').split(".").reverse().join("-");
      } else {
        let errorMessage = 'Lütfen tarih seçiniz';
        if (currentError === 'minDate') {
          errorMessage = 'Lütfen geçerli bir tarih seçiniz'
        }
        setErrorMessage(errorMessage);
        setFormError(true);
        return;
      }

      body.status = 'ASSIGNED';
      body.dueDate = dueDate;
      body.organizationId = data.get('unit');
    } else if (type === 'reject') {
      body.status = 'NOT_APPROVED';
      body.returnReason = data.get('message');
    }

    try {
      await TaskService.updateRequestedTask(task.id, body);
      handleCancel();
      handLeOk();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Görev güncellenemedi, lütfen daha sonra tekrar deneyiniz');
      }
      setFormError(true);
    }
  };

  return (
    <div>
      <MenuItem onClick={handleOpen}>
      <Iconify icon={buttonIcon} sx={{ mr: 2 }} />
        {buttonText}
      </MenuItem>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 600 } }}
        maxWidth="xs"
        open={open}
        onClose={handleCancel}
      >
        <DialogTitle>{buttonText}</DialogTitle>
        <Box component="form" onSubmit={handleConfirm} sx={{ mt: 0}}>
          <Grid container spacing={2}>
            {type === 'update' ? (
              <Grid item xs={12}>
              <DialogContent dividers>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      defaultValue={task.subject}
                      autoComplete="Konu"
                      name="subject"
                      fullWidth
                      id="message"
                      label="Konu"
                      autoFocus
                      multiline
                      rows={3}
                      maxRows={3}
                      inputProps={{ maxLength: 255 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      defaultValue={task.description}
                      autoComplete="Açıklama"
                      name="description"
                      fullWidth
                      id="message"
                      label="Açıklama"
                      autoFocus
                      multiline
                      rows={3}
                      maxRows={3}
                      inputProps={{ maxLength: 255 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                        defaultValue={task.urgency}
                        id="urgency"
                        name="urgency"
                        select
                        label="Aciliyet"
                        required
                        fullWidth
                      >
                      <MenuItem value={"LOW"}>Düşük Aciliyet</MenuItem>
                      <MenuItem value={"MEDIUM"}>Orta Aciliyet</MenuItem>
                      <MenuItem value={"HIGH"}>Yüksek Aciliyet</MenuItem>
                    </TextField>
                  </Grid>
                    { formError ? <Grid item xs={12} sx={{ mt: 1 }}>
                        <Alert severity="error">{errorMessage ? errorMessage : 'Görev güncellenemedi, lütfen daha sonra tekrar deneyiniz.'}</Alert>
                    </Grid> : <></> }
                  </Grid>
                </DialogContent>
               </Grid>
            ) : (<></>)}
            {type === 'approve' ? (
             <Grid item xs={12}>
              <DialogContent dividers>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="unit"
                      name="unit"
                      select
                      label="Birim"
                      required
                      fullWidth
                    >
                      {units.map(unit => <MenuItem value={unit.id}>{unit.name}</MenuItem>)}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns}>
                      <DatePicker
                        minDate={new Date()}
                        sx={{ width: '100%' }}
                        label="Bitiş Tarihi"
                        onError={(reason, value) => {
                          if (reason) {
                            // reason is the error , which it will be displayed as
                            // default error message ,you can also  pass your own error
                            // message depending on the error
              
                            setCurrentError(reason);
                            setErrorDate(true);
                          } else {
                            setCurrentError(null);
                            setErrorDate(false);
                          }
                        }}
                        localeText={{
                          cancelButtonLabel: "İptal",
                          okButtonLabel: "Tamam"
                        }}
                        slotProps={{ textField: { required: true, id: 'dueDate', name: 'dueDate' }, toolbar: { hidden: true } }}/>
                      </LocalizationProvider> 
                    </Grid>
                    { formError ? <Grid item xs={12} sx={{ mt: 1 }}>
                        <Alert severity="error">{errorMessage ? errorMessage : 'Görev güncellenemedi, lütfen daha sonra tekrar deneyiniz.'}</Alert>
                    </Grid> : <></> }
                  </Grid>
                </DialogContent>
               </Grid>
              ) : (<></>)}
            {type === 'reject' ? (
              <Grid item xs={12}>
                <DialogContent dividers>
                  <TextField
                    autoComplete="Konu"
                    name="message"
                    fullWidth
                    id="message"
                    label="Açıklama"
                    autoFocus
                    multiline
                    rows={3}
                    maxRows={3}
                    inputProps={{ maxLength: 255 }}
                  />
                </DialogContent>
                { formError ? <Grid item xs={12} sx={{ mt: 3 }}>
                    <Alert severity="error">{errorMessage ? errorMessage : 'Görev güncellenemedi, lütfen daha sonra tekrar deneyiniz.'}</Alert>
                </Grid> : <></> }
              </Grid>
            ) : (<></>)}
          
          <Grid item xs={12}>
            <DialogActions>
              <Button variant='outlined' color='error' autoFocus onClick={handleCancel}>
                İptal
              </Button>
              <Button variant='outlined' color='primary' type="submit">Onayla</Button>
            </DialogActions>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </div>
  );
}

