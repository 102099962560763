import Axios from 'axios';
import Cookie from '../utils/cookie';
import UserService from '../services/UserService';

const axiosApiInstance = Axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async config => {
    const access_token = await Cookie.getCookie("access_token")
    if (access_token) {
      config.headers = { 
        'Authorization': `Bearer ${access_token}`,
        'Accept': 'application/json',
        'Content-Type': config.headers && config.headers['Content-Type'] ? config.headers['Content-Type'] : 'application/json'
      }
    }
    return config;
  },
  error => {
    Promise.reject(error)
});
// Response interceptor for API calls
axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const access_token = await UserService.refreshToken();            
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    return axiosApiInstance(originalRequest);
  }
  throw Promise.reject(error);
});

export const getAxiosInstance = () => {
  return axiosApiInstance;
}