import PropTypes from 'prop-types';
import { useEffect, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Drawer, Typography } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import { UserContext } from '../../../App';
import UserService from '../../../services/UserService';
import { useNavigate } from 'react-router-dom';
import {ROLE_LOWER_MAP} from '../../../locales/constants';
import BackgroundLetterAvatars from '../../../components/avatar/BackgroundLetterAvatars';
import Cookie from '../../../utils/cookie';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { Capacitor } from '@capacitor/core';
// ----------------------------------------------------------------------

const NAV_WIDTH = 300;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const [rootStyle, setRootStyle] = useState({});
  const [organization, setOrganization] = useState('');

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isDesktop = useResponsive('up', 'lg');
  const { user, setUser } = useContext(UserContext);
  
  const setUserContext = async () => {
    const accessToken = await Cookie.getCookie('access_token');
    const userDetails = await UserService.getUserDetails();

    if (!userDetails.isAxiosError) {
      const userContext = {
        fullName: userDetails.name + " " + userDetails.surname,
        userName: userDetails.userName,
        role: userDetails.role.name,
        access_token: accessToken,
      };
  
      setUser(userContext);
    }
  }

  const addSafeAreaEvent = async () => {
    SafeArea.addListener('safeAreaChanged', data => {
      const { insets } = data;
      setRootStyle({
        paddingTop: insets.top  + 'px',
        paddingLeft: insets.left + 'px'
      });
    });
  }

  useEffect(() => {
    if (Capacitor.getPlatform() == 'ios') {
      SafeArea.getSafeAreaInsets().then(({ insets }) => {
        setRootStyle({
          paddingTop: insets.top + 'px',
          paddingLeft: insets.left  + 'px'
        });
      });
  
      addSafeAreaEvent();
    }
    
    const fetchUser = async () => {
      try {
        await setUserContext();
      } catch(e) {
        await Cookie.logout();

        navigate('/login', { replace: true });
      }
    }

    const setMobileOrganization = async () => {
      if (Capacitor.isNativePlatform()) {
        const organization = await Cookie.getName('organization');
        setOrganization(organization);
      }
    }

    if (openNav) {
      onCloseNav();
    }

    if (!user) {
      fetchUser();
    }

    setMobileOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const navConfigWithAuth = () => {
    if (user) {
      return navConfig.filter(conf => conf.roles.includes(user.role));
    }
    return [];
  }

  const getLogo = () => {
    if (Capacitor.isNativePlatform()) {
      const logoSrc = `/assets/page-logo-${organization}.png`;
      return (
        <Box sx={{ pl: 2.5, pr: 1.5, py: 4, display: 'inline-flex' }}>
          <Logo 
            src={logoSrc} 
            styled={{}}
          />
        </Box>
      );
    }

    return (
      <Box sx={{ pl: 2.5, pr: 1.5, py: 4, display: 'inline-flex' }}>
        <Logo 
          src={'/assets/page-logo.png'} 
          styled={{}}
        />
      </Box>
    );
  }

  const renderContent = (
    <Scrollbar
      sx={{
        ...rootStyle,
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      
      {getLogo()}

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <BackgroundLetterAvatars fullName={user ? user.fullName : ''} />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {user ? user.fullName : ''}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {user ? ROLE_LOWER_MAP[user.role] : ''}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={navConfigWithAuth()} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
