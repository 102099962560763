import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, center = false, src, styled, sx, ...other }, ref) => {

  const style = styled ? styled : {
    display: 'inline-flex',
    width: { md: '200px', sm: '130px', xs: '110px' },
    ...sx,
  };

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={center ? { width: { md: '200px', sm: '130px', xs: '110px' }, textAlign: 'center' } : style}
      {...other}
    >
      <img src={src} alt=''></img>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
