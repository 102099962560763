import PropTypes from 'prop-types';
import Axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Avatar,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import NotificationService from '../../../services/NotificationService';
import { webFirebaseInit, mobileFirebaseInit, onMessageListener } from '../../../firebase';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import CloseIcon from '@mui/icons-material/Close';
import { Capacitor } from '@capacitor/core';
import { publish, subscribe, unsubscribe } from '../../../utils/events';
import { SafeArea } from 'capacitor-plugin-safe-area';
// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const [notifications, setNotifications] = useState([]);
  const [rootStyle, setRootStyle] = useState({});

  const totalUnRead =  notifications.filter((item) => item.markRead === false).length;
  const totalRead = notifications.filter((item) => item.markRead === true).length;

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const [snackOpen, setSnackOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  const handleSnackOpen = (message) => {
    setNotificationMessage(message);
    setSnackOpen(true);
    fetchNotifications();
    publish('notification-received');
  };

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleSnackClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const handleMarkAllAsRead = async () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        markRead: true,
      }))
    );

    try {
      await NotificationService.markReadNotifications();
    } catch(e) {
      console.log(e);
    }
  };

  const handleMarkAsRead = async (event) => {
    if (event.markRead) {
      return;
    }

    const updated = notifications.map((ele) => {
      if (ele.id === event.id) {
        ele.markRead = true;
      }

      return ele;
    });

    setNotifications(updated);

    try {
      await NotificationService.markReadNotidication(event.id);
    } catch(e) {
      console.log(e);
    }
  };

  const fetchNotifications = async () => {
    try {
      const response = await NotificationService.getNotifications();

      if (!response.isAxiosError) {
        setNotifications(response);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const addSafeAreaEvent = async () => {
    SafeArea.addListener('safeAreaChanged', data => {
      const { insets } = data;
      setRootStyle({
        paddingTop: insets.top  + 'px',
        paddingLeft: insets.left + 'px',
        paddingRight: insets.right  + 'px'
      });
    });
  }

  useEffect(() => {
    if (Capacitor.getPlatform() == 'ios') {
      SafeArea.getSafeAreaInsets().then(({ insets }) => {
        setRootStyle({
          paddingTop: insets.top + 'px',
          paddingLeft: insets.left  + 'px',
          paddingRight: insets.right  + 'px'
        });
      });
  
      addSafeAreaEvent();
    }

    if(Capacitor.isNativePlatform()) {
      mobileFirebaseInit(handleSnackOpen);
    } else {
      webFirebaseInit();
    }

    fetchNotifications();
    subscribe('refresh-page', eventListener);
    
    return () => {
      unsubscribe('refresh-page', eventListener);
    }
  }, []);

  onMessageListener()
    .then(payload => {
      const notification = payload.data;
      if (notification.snackbarMessage) {
        handleSnackOpen(notification.snackbarMessage);
      }
    })
    .catch(err => console.log('failed: ', err));

  const eventListener = useCallback(function (e) {
    fetchNotifications();
  }, []);
    
  return (
    <>
      <Box >
        <Snackbar
          sx={rootStyle}
          anchorOrigin={{
             vertical: "top",
             horizontal: "right"
          }}
          open={snackOpen}
          autoHideDuration={3000}
          onClose={handleSnackClose}
        >
          <SnackbarContent
            className="flex justify-center bg-slate-200"
            sx={{ backgroundColor: '#0C53B7', color: 'white', maxWidth: '100%', height: 'auto', fontSize: { md: '0.875rem', xs: '0.800rem'} }}
            message={notificationMessage}
            action={action}
          />
        </Snackbar>
      </Box>

      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 60, height: 60 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify width="24px" icon="eva:bell-fill" />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
            maxHeight: 500
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Bildirimler</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Toplam {totalUnRead} okunmamış bildiriminiz var
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Tümünü okundu olarak işaretle">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>

          {totalUnRead > 0 ? (
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  Yeni
                </ListSubheader>
              }
            >
              {notifications.filter(ele => ele.markRead === false).map((notification) => (
                <NotificationItem key={notification.id} notification={notification} handleMarkAsRead={handleMarkAsRead} />
              ))}
            </List>
          ) : (<></>)}

          {totalRead > 0 ? (
            <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Eski
              </ListSubheader>
            }
          >
            {notifications.filter(ele => ele.markRead === true).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} handleMarkAsRead={handleMarkAsRead}/>
            ))}
          </List>
          ) : (<></>)}
          
        </Scrollbar>
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    markRead: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string
  }),
};

function NotificationItem({ notification, handleMarkAsRead }) {
  const { title } = renderContent(notification);

  return (
    <ListItemButton
      onClick={() => handleMarkAsRead(notification)}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        opacity: 1,
        ...(!notification.markRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral', color: 'grey' }}><AssignmentIcon /></Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {notification.message}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_package.svg" />,
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_shipping.svg" />,
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_mail.svg" />,
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_chat.svg" />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
