import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import Cookies from 'universal-cookie';

const cookie = new Cookies();

class Cookie {
  static async login(response, clientId) {
    if (Capacitor.isNativePlatform()) {
      this.setName("access_token", response.data.access_token)
      this.setName("refresh_token", response.data.refresh_token)
      this.setName("token_type", response.data.token_type)
      this.setName("clientId", clientId)
    } else {
      cookie.set("access_token", response.data.access_token, { path: '/' });
      cookie.set("refresh_token", response.data.refresh_token, { path: '/' });
      cookie.set("token_type", response.data.token_type, { path: '/' });
      cookie.set("clientId", clientId, { path: '/' });
    }
  }

  static async refreshToken(response) {
    if (Capacitor.isNativePlatform()) {
      this.setName("access_token", response.data.access_token)
      this.setName("refresh_token", response.data.refresh_token)
      this.setName("token_type", response.data.token_type)
    } else {
      cookie.set("access_token", response.data.access_token, { path: '/' });
      cookie.set("refresh_token", response.data.refresh_token, { path: '/' });
      cookie.set("token_type", response.data.token_type, { path: '/' });
    }
  }
  
  static async logout() {
    if (Capacitor.isNativePlatform()) {
      this.removeName("access_token");
      this.removeName("refresh_token");
      this.removeName("token_type");
      this.removeName("clientId");
    } else {
      cookie.remove("access_token", { path: '/' });
      cookie.remove("refresh_token", { path: '/' });
      cookie.remove("token_type", { path: '/' });
      cookie.remove("clientId", { path: '/' });
    }
  }
  
  static async getCookie(key) {
    if (Capacitor.isNativePlatform()) {
      return this.getName(key)
    } else {
      return cookie.get(key)
    }
  }
  
  static async setName(key, value) {
    await Preferences.set({
      key: key,
      value: value,
    });
  };

  static async getName(key) {
    const { value } = await Preferences.get({ key: key });
    return value;
  };
  
  static async removeName(key) {
    await Preferences.remove({ key: key });
  };
}

export default Cookie;