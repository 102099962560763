import React, { useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import UserService from '../../services/UserService';
import TaskService from '../../services/TaskService';
import Alert from '@mui/material/Alert';
import { UserContext } from '../../App';
import UnitService from '../../services/UnitService';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { tr } from "date-fns/locale";
import { Upload } from 'antd';

const { Dragger } = Upload;

export default function Assignment() {
  
  const { user } = useContext(UserContext);

  const [users, setUsers] = useState([]);
  const [units, setUnits] = useState([]);
  const [formError, setFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [manager, setManager] = useState(false);
  const [townManager, setTownManager] = useState(false);

  const [currentError, setCurrentError] = useState(null);
  const [errorDate, setErrorDate] = useState(false);

  const [fileList, setFileList] = useState([]);

  useEffect(() => { 
    const fetchUsers = async () => {
      if (user && user.role === "ROLE_ADMIN") {
        setManager(false);

        try {
          const unitList = await UnitService.getUnits();

          if (!unitList.isAxiosError) {
            setUnits(unitList);
          }
        } catch (e) {
          console.log(e);
        }
      } else if (user && user.role === "ROLE_MANAGER") {
        setManager(true);

        try {
          const userList = await UserService.getUsers();
          if (!userList.isAxiosError) {
            const sortedList = userList.sort((a,b)=>{
              if (a.userName === user.userName) {
                return -1;
              }
      
              if (b.userName === user.userName) {
                return 1;
              }
      
              return 0;
            });
    
            setUsers(sortedList);
          }
        } catch (e) {
          console.log(e);
        }
      } else if (user && user.role === 'ROLE_TOWN_MANAGER') {
        setManager(false);
        setTownManager(true);
      }
    }
    
    fetchUsers();
  }, [user]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormError(false);

    const data = new FormData(event.currentTarget);

    let dueDate = '';
    if (!townManager) {
      if (data.get('dueDate') && !errorDate) {
        dueDate = data.get('dueDate').split(".").reverse().join("-");
      } else {
        let errorMessage = 'Lütfen tarih seçiniz';
        if (currentError === 'minDate') {
          errorMessage = 'Lütfen geçerli bir tarih seçiniz'
        }
        setErrorMessage(errorMessage);
        setFormError(true);
        return;
      }
    }
    
    const formData = new FormData();

    if (fileList && fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append('files[]', file);
      });
    }

    const body = {
      urgency: data.get('urgency'),
      subject: data.get('subject'),
      description: data.get('description'),
      dueDate: dueDate
    };

    if (manager) {
      body.assigneeUserName = data.get('assigneeUserName');
    } else if (townManager) {
      body.dueDate = '';
    } else {
      body.organizationId = data.get('organizationId');
    }

    const json = JSON.stringify(body);
    const blob = new Blob([json], {
      type: 'application/json'
    });

    formData.append('taskCreateRequest', blob);

    try {
      if (townManager) {
        await TaskService.requestTask(formData);
      } else {
        await TaskService.createTask(formData);
      }
      
      clearInputs(event);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Görev oluşturulamadı, lütfen daha sonra tekrar deneyiniz');
      }
      setFormError(true);
    }
  };

  const props = {
    height: 60,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const clearInputs = (event) => {
    event.target.reset();
    setFileList([]);
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <AssignmentIndIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {townManager ? 'Görev Talep Et' : 'Görev Oluştur' }
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="Konu"
                name="subject"
                required
                fullWidth
                id="subject"
                label="Konu"
                autoFocus
                multiline
                rows={3}
                maxRows={3}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="description"
                label="Açıklama"
                name="description"
                autoComplete="Açıklama"
                multiline
                rows={3}
                maxRows={3}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="urgency"
                name="urgency"
                select
                label="Aciliyet"
                defaultValue=""
                required
                fullWidth
              >
                <MenuItem value={"LOW"}>Düşük Aciliyet</MenuItem>
                <MenuItem value={"MEDIUM"}>Orta Aciliyet</MenuItem>
                <MenuItem value={"HIGH"}>Yüksek Aciliyet</MenuItem>
              </TextField>
            </Grid>
            {townManager ? (<></>) : (
              <Grid item xs={12}>    
                {manager ? (
                  <TextField
                    id="assigneeUserName"
                    name="assigneeUserName"
                    select
                    label="Atanacak Kullanıcı"
                    defaultValue=""
                    required
                    fullWidth
                  >
                    {users.map((value) => (<MenuItem value={value.userName}>{user.userName === value.userName ? 'Kendim' : value.name + " " + value.surname}</MenuItem>))}
                  </TextField>) : (
                  <TextField
                    id="organizationId"
                    name="organizationId"
                    select
                    label="Atanacak Birim"
                    defaultValue=""
                    required
                    fullWidth
                    >
                    {units.map(unit => (<MenuItem value={unit.id}>{unit.name}</MenuItem>))}
                  </TextField>
                )}
              </Grid>
            )}
            {townManager ? (<></>) : (
              <Grid item xs={12}>    
                <LocalizationProvider adapterLocale={tr} dateAdapter={AdapterDateFns}>
                  <DatePicker
                    minDate={new Date()}
                    sx={{ width: '100%' }}
                    label="Bitiş Tarihi"
                    onError={(reason, value) => {
                      if (reason) {
                        // reason is the error , which it will be displayed as
                        // default error message ,you can also  pass your own error
                        // message depending on the error
          
                        setCurrentError(reason);
                        setErrorDate(true);
                      } else {
                        setCurrentError(null);
                        setErrorDate(false);
                      }
                    }}
                    localeText={{
                      cancelButtonLabel: "İptal",
                      okButtonLabel: "Tamam"
                    }}
                    slotProps={{ textField: { required: true, id: 'dueDate', name: 'dueDate' }, toolbar: { hidden: true } }}/>
                </LocalizationProvider> 
              </Grid>
             )}
            </Grid>
            <Grid item xs={12} sx={{ mt: 2}}>    
              <Dragger {...props}>
                <p className="ant-upload-text">Fotoğraf Ekle</p>
              </Dragger>
            </Grid>
          { formError ? <Grid item xs={12} sx={{ mt: 3 }}>
              <Alert severity="error">{errorMessage ? errorMessage : 'Görev oluşturulamadı, lütfen daha sonra tekrar deneyiniz'}</Alert>
          </Grid> : <></>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, minHeight:'46px !important' }}
          >
            Kaydet
          </Button>
        </Box>
      </Box>
    </Container>
  );
}