import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { Capacitor } from '@capacitor/core';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 0,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [rootStyle, setRootStyle] = useState({});

  const addSafeAreaEvent = async () => {
    SafeArea.addListener('safeAreaChanged', data => {
      const { insets } = data;
      setRootStyle({
        paddingTop: insets.top,
        paddingRight: insets.right,
        paddingLeft: insets.left
      });
    });
  }

  useEffect(() => {
    if (Capacitor.getPlatform() == 'ios') {
      SafeArea.getSafeAreaInsets().then(({ insets }) => {
        setRootStyle({
          paddingTop: insets.top,
          paddingBottom: insets.bottom,
          paddingRight: insets.right,
          paddingLeft: insets.left
        });
      });
  
      addSafeAreaEvent();
    }
  }, rootStyle);

  return (
    <StyledRoot style={rootStyle}>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <Main sx={{backgroundColor: "#F9FAFB"}}>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
